import React, { useState } from "react";
import useRastaPrice from "../../hooks/useRastaPrice";
import useMRastaPrice from "../../hooks/useMRastaPrice";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  // FaHome,
  // RiErrorWarningFill,
  // IoIosSwap,
  // RiCoinLine,
  // GiTwoCoins,
  // GiLion,
  // IoChatboxEllipsesOutline,
  // FaTelegramPlane,
  // FaTwitter,
  // FaMediumM,
  // FaReddit,
  FaAlignLeft,
  FaRegWindowClose,
  // GoMail,
  // FaDonate,
} from "react-icons/all";
// import { useWallet } from "@binance-chain/bsc-use-wallet";
import MenuItem from "./MenuItem";
import MRastaIcon from "../../assets/menu_coin1.jpg";
import RastaIcon from "../../assets/menu_coin2.jpg";
// import { adminlist } from "../../config/config";
// import { useArtists } from "../../hooks/useAllNFT";
// import ZLPLogo from "../../assets/zlp-logo.png";
// import ZLPLogo from "../../assets/zlp-by-rasta.png";
import ZLPLogo from "../../assets/newimage/ZION-logo-white.png";


export default function Sidebar() {
  const [sidebar, setSidebar] = useState(false);
  // const { account } = useWallet();
  // const artists = useArtists();
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  const rastaPriceUsd = useRastaPrice();
  const mRastaPriceUsd = useMRastaPrice();

  const menu = [
    {
      label: 'Home',
      path: 'https://zionroyal.com/',
      parent: false,
      child: [],
    },
    {
      label: 'About',
      path: '#',
      parent: true,
      child: [
        {
          path: 'https://zionroyal.com/about',
          label: 'Zion Royal ',
        },
        {
          path: 'https://zionroyal.com/win-big',
          label: 'Win Big',
        },
      ]
    },
    {
      label: 'NFT Transporter',
      path: 'https://send.zionroyal.com/',
      parent: false,
      child: [],
    },
    {
      label: 'Mint',
      path: '#',
      parent: true,
      child: [
        {
          path: 'https://zionroyal.com/mint',
          label: 'Zion Lions',
        },
        {
          path: 'https://www.zionlp.com/RaggadatCris/Melody_1',
          label: 'Raggadat Cris',
        },
      ],
    },
    {
      label: 'Staking',
      path: '#',
      parent: true,
      child: [
        {
          path: 'https://zionroyal.com/stake-zionlions',
          label: 'Zion Lion Staking',
        },
        {
          path: 'https://zionroyal.com//stakenft',
          label: 'DividendNFT',
        },
        {
          path: 'https://zionroyal.com/liquidity',
          label: 'Liquidity',
        },
        // {
        //   path: 'https://zionroyal.com/pools',
        //   label: 'Rasta',
        // },
      ],
    },
    {
      label: 'Farmers Flip',
      path: 'https://flip.zionroyal.com/',
      parent: false,
      child: [],
    },
    {
      label: 'Launchpad',
      path: '#',
      parent: true,
      child: [
        {
          path: '/launchpad-artists',
          label: 'Home',
        },
        {
          path: '/',
          label: 'Marketplace',
        },
        {
          path: '/RaggadatCris/Melody_1/stake',
          label: 'Raggadat Cris',
        },
      ],
    },
    // {
    //   // label: 'Dex',
    //   label: 'Buy',
    //   path: 'https://swap.zionlabs.info/',
    //   parent: false,
    //   child: [],
    // },
    {
      label: 'View More...',
      path: '#',
      parent: true,
      child: [
        {
          path: 'https://docs.zionlabs.info',
          label: 'Whitepaper',
        },
        {
          path: 'https://docs.zionroyal.com/faq/rasta-audit',
          label: 'Audit',
        },
        {
          path: 'https://coinmarketcap.com/currencies/rasta-finance/',
          label: 'CoinMarketCap',
        },
          {
            label: 'Docs',
            path: 'https://docs.zionlabs.info',
          },
          {
            label: 'Education',
            path: 'https://zionroyal.com/educations',
          }
      ],
    },
    {
      label: 'Contact',
      path: 'https://zionroyal.com/contact',
      parent: false,
      child: [],
    },
  ]

  // const menu = [
  //   {
  //     label: 'Home',
  //     path: 'https://zionroyal.com/',
  //     // icon: <FaHome className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: 'About',
  //     path: 'https://zionroyal.com/about',
  //     // icon: <RiErrorWarningFill className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     // label: 'Batch NFT Transporter',
  //     // label: 'NFT Swap',
  //     // label: 'NFT Center',
  //     label: 'NFT Sender',
  //     path: 'https://send.zionroyal.com/',
  //     // icon: <FaHome className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: 'NFT Staking',
  //     // label: 'Zion Lion NFTs',
  //     path: '#',
  //     // icon: <FaDonate className="inline-block" style={{ width: '32px', height: '28px' }} />,
  //     parent: true,
  //     child: [
  //       // {
  //       //   path: '/stakenft',
  //       //   label: 'DividendNFT',
  //       // },
  //       // {
  //       //   path: '/stake-zionlions',
  //       //   label: 'ZionBuilders',
  //       // },
  //       // {
  //       //   path: '/stake-zionlions',
  //       //   label: 'ZionLion NFT',
  //       // },
  //       {
  //         path: 'https://zionroyal.com/mint',
  //         label: 'Mint',
  //       },
  //       {
  //         path: 'https://zionroyal.com/stake-zionlions',
  //         label: 'NFT Staking',
  //       },
  //       // {
  //       //   path: '/#mintstation',
  //       //   label: 'NFT Staking',
  //       // },
  //       {
  //         path: 'https://zionroyal.com/stakenft',
  //         label: 'DividendNFT',
  //       },
  //     ],
  //   },
  //   {
  //     label: 'Launchpad',
  //     path: 'https://zionlp.com/launchpad-artists',
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: 'Win Big',
  //     path: 'https://zionroyal.com/win-big',
  //     parent: false,
  //     child: [],
  //   },
  //   // {
  //   //   label: 'About',
  //   //   path: '/about',
  //   //   // icon: <RiErrorWarningFill className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //   parent: false,
  //   //   child: [],
  //   // },
  //   // {
  //   //   label: 'Docs',
  //   //   path: 'https://docs.zionlabs.info',
  //   //   parent: false,
  //   //   child: [],
  //   // },
  //   {
  //     label: 'RastaSwap ★',
  //     path: 'https://swap.zionlabs.info/',
  //     parent: false,
  //     child: [],
  //   },
  //   // {
  //   //   label: 'Trade',
  //   //   path: '#',
  //   //   // icon: <IoIosSwap className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //   parent: true,
  //   //   child: [
  //   //     {
  //   //       path: 'https://swap.zionlabs.info/',
  //   //       label: 'RastaSwap ★',
  //   //     },
  //   //     {
  //   //       path: 'https://exchange.zionlabs.info',
  //   //       label: 'Old RastaDex',
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     label: 'Stake Rasta',
  //     path: 'https://zionroyal.com/pools',
  //     // icon: <GiTwoCoins className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: 'Stake Liquidity',
  //     path: 'https://zionroyal.com/liquidity',
  //     // icon: <RiCoinLine className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: 'Docs',
  //     path: 'https://docs.zionlabs.info',
  //     parent: false,
  //     child: [],
  //   },
  //   // {
  //   //   label: 'Launchpad',
  //   //   path: 'https://zionlp.com/launchpad-artists',
  //   //   parent: false,
  //   //   child: [],
  //   // },
  //   // blog
  //   {
  //     label: 'Education',
  //     path: 'https://zionroyal.com/educations',
  //     parent: false,
  //     child: [],
  //   },
  //   // end blog
  //   // {
  //   //   label: 'Zion Launchpad',
  //   //   path: '#',
  //   //   // icon: <GiLion className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //   parent: true,
  //   //   child: [
  //   //     {
  //   //       path: 'https://zionlp.com/launchpad-artists',
  //   //       label: 'Launchpad Artists',
  //   //       blank: true,
  //   //     },
  //   //     {
  //   //       path: 'https://zionlp.com',
  //   //       label: 'Launchpad NFTs',
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   label: 'NFT Staking',
  //   //   path: '#',
  //   //   // icon: <FaDonate className="inline-block" style={{ width: '32px', height: '28px' }} />,
  //   //   parent: true,
  //   //   child: [
  //   //     {
  //   //       path: '/stakenft',
  //   //       label: 'DividendNFT',
  //   //     },
  //   //     // {
  //   //     //   path: '/stake-zionlions',
  //   //     //   label: 'ZionBuilders',
  //   //     // },
  //   //     {
  //   //       path: '/stake-zionlions',
  //   //       label: 'ZionLion NFT',
  //   //     },
  //   //     // {
  //   //     //   path: '/streetpunksnft',
  //   //     //   label: 'Streetpunk NFT',
  //   //     // },
  //   //   ],
  //   // },
  //   // {
  //   //  label: 'Rasino',
  //   //  sublabel:'(coming soon)',
  //   //  plusLabel: '(Coming Soon)',
  //   //  path: '#',
  //   //  icon: <RiMoneyDollarCircleLine className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //  parent: false,
  //   //  child: [],
  //   // },
  //   // {
  //   //   label: 'Stake Liquidity',
  //   //   path: '/liquidity',
  //   //   // icon: <RiCoinLine className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //   parent: false,
  //   //   child: [],
  //   // },
  //   // {
  //   //   label: 'Stake Rasta',
  //   //   path: '/pools',
  //   //   // icon: <GiTwoCoins className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //   parent: false,
  //   //   child: [],
  //   // },
  //   // {
  //   //   label: 'StakeRasta',
  //   //   path: '/stake',
  //   //   // icon: <RiMoneyDollarCircleLine className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //   parent: false,
  //   //   child: [],
  //   // },
  //   // {
  //   //  label: 'TOP LAUNCH PAD',
  //   //  sublabel:'(coming soon)',
  //   //  path: '#',
  //   //  icon: <AiFillStar className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //  parent: false,
  //   //  child: [],
  //   // },
  //   // {
  //   //   label: 'TECHRATE AUDIT',
  //   //   path: 'https://zionlabs.info/files/RastaAudit.pdf',
  //   //  icon: <FaBook className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //   //  parent: false,
  //   //  child: [],
  //   // },
  //   {
  //     label: 'View More...',
  //     path: '#',
  //     // icon: <IoChatboxEllipsesOutline className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: true,
  //     child: [
  //       {
  //         path: 'https://docs.zionlabs.info',
  //         label: 'Whitepaper',
  //       },
  //       {
  //         path: 'https://docs.zionroyal.com/faq/rasta-audit',
  //         label: 'Audit',
  //       },
  //       {
  //         path: 'https://coinmarketcap.com/currencies/rasta-finance/',
  //         label: 'CoinMarketCap',
  //       },

  //       // {
  //       //   path: 'https://rastafinance.medium.com',
  //       //   label: 'Medium',
  //       // },
  //       // {
  //       //   path: 'https://dappradar.com/binance-smart-chain/defi/rasta-finance',
  //       //   label: 'Dapp Radar',
  //       // },
  //       // {
  //       //   path: 'https://dex.guru/token/0xe3e8cc42da487d1116d26687856e9fb684817c52-bsc',
  //       //   label: 'Chart',
  //       // },
  //     ],
  //   },
  //   {
  //     label: 'Contact',
  //     path: 'https://zionroyal.com/contact',
  //     // icon: <GoMail className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: false,
  //     child: [],
  //   },
  // ]

  // const menu = [
  //   {
  //     label: "Home",
  //     path: "https://zionroyal.com/",
  //     //   icon: (
  //     //     <FaHome
  //     //       className="inline-block"
  //     //       style={{ width: "32px", height: "32px" }}
  //     //     />
  //     //   ),
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: "NFT Staking",
  //     path: "#",
  //     //   icon: <FaDonate className="inline-block" style={{ width: '32px', height: '28px' }} />,
  //     parent: true,
  //     child: [
  //       {
  //         path: "https://zionroyal.com/stakenft",
  //         label: "DividendNFT",
  //       },
  //       {
  //         path: "http://zionlabs.info/stake-zionlions",
  //         label: "ZionLion NFT",
  //       },
  //       {
  //         path: "http://zionlabs.info/#mintstation",
  //         label: "NFT Staking",
  //       },
  //     ],
  //   },
  //   {
  //     label: "Launchpad",
  //     path: "#",
  //     parent: true,
  //     child: [
  //       {
  //         path: "/",
  //         label: "NFTs",
  //       },
  //       // {
  //       //   path: "/launchpad-artists",
  //       //   label: "Artists",
  //       // },
  //     ],
  //   },
  //   {
  //     label: "About",
  //     path: "https://app.rasta.finance/about",
  //     //   icon: (
  //     //     <RiErrorWarningFill
  //     //       className="inline-block"
  //     //       style={{ width: "32px", height: "32px" }}
  //     //     />
  //     //   ),
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: "Docs",
  //     path: "https://docs.zionlabs.info",
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: "RastaSwap ★",
  //     path: "https://swap.zionlabs.info/",
  //     parent: false,
  //     child: [],
  //   },
  //   // {
  //   //   label: "Trade",
  //   //   path: "#",
  //   // //   icon: (
  //   // //     <IoIosSwap
  //   // //       className="inline-block"
  //   // //       style={{ width: "32px", height: "32px" }}
  //   // //     />
  //   // //   ),
  //   //   parent: true,
  //   //   child: [
  //   //     {
  //   //       path: "https://swap.rasta.finance/",
  //   //       label: "RastaSwap ★",
  //   //     },
  //   //     {
  //   //       path: "https://exchange.rasta.finance",
  //   //       label: "Old RastaDex",
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     label: "Stake Rasta",
  //     path: "https://app.rasta.finance/pools",
  //     // icon: <RiCoinLine className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: false,
  //     child: [],
  //   },
  //   {
  //     label: "Stake Liquidity",
  //     path: "https://app.rasta.finance/liquidity",
  //     // icon: <RiCoinLine className="inline-block" style={{ width: '32px', height: '32px' }} />,
  //     parent: false,
  //     child: [],
  //   },
  //   // {
  //   //     label: "Launchpad",
  //   //     path: "/launchpad-artists",
  //   //     parent: false,
  //   //     child: [],
  //   // },
  //   // {
  //   //   label: "Zion Launchpad",
  //   //   path: "#",
  //   // //   icon: (
  //   // //     <GiLion
  //   // //       className="inline-block"
  //   // //       style={{ width: "32px", height: "32px" }}
  //   // //     />
  //   // //   ),
  //   //   parent: true,
  //   //   child: [
  //   //     {
  //   //       path: "/",
  //   //       label: "Launchpad NFTs",
  //   //     },
  //   //     {
  //   //       path: "/launchpad-artists",
  //   //       label: "Launchpad Artists",
  //   //     },
  //   //     {
  //   //       path: "/create",
  //   //       label: "Create",
  //   //       hidden: adminlist.indexOf(account) === -1,
  //   //     },
  //   //     {
  //   //       path: "/artist-profile",
  //   //       label: "Artist Page",
  //   //       hidden: artists ? artists.indexOf(account) === -1 : true,
  //   //     },
  //   //     {
  //   //       path: `/list/owner/${account}`,
  //   //       label: "My NFTs",
  //   //       hidden: !Boolean(account),
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   label: "NFT Staking",
  //   //   path: "#",
  //   //   //   icon: <FaDonate className="inline-block" style={{ width: '32px', height: '28px' }} />,
  //   //   parent: true,
  //   //   child: [
  //   //     {
  //   //       path: "https://zionroyal.com/stakenft",
  //   //       label: "DividendNFT",
  //   //     },
  //   //     {
  //   //       path: "http://zionlabs.info/stake-zionlions",
  //   //       label: "ZionLion NFT",
  //   //     },
  //   //     {
  //   //       path: "http://zionlabs.info/#mintstation",
  //   //       label: "NFT Staking",
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   label: "Farms",
  //   //   path: "#",
  //   // //   icon: (
  //   // //     <RiCoinLine
  //   // //       className="inline-block"
  //   // //       style={{ width: "32px", height: "32px" }}
  //   // //     />
  //   // //   ),
  //   //   parent: true,
  //   //   child: [
  //   //     {
  //   //       path: "https://app.rasta.finance/farms",
  //   //       label: "Mr. Rasta",
  //   //     },
  //   //   ],
  //   // },

  //   // {
  //   //   label: "Pools",
  //   //   path: "#",
  //   // //   icon: (
  //   // //     <GiTwoCoins
  //   // //       className="inline-block"
  //   // //       style={{ width: "32px", height: "32px" }}
  //   // //     />
  //   // //   ),
  //   //   parent: true,
  //   //   child: [
  //   //     {
  //   //       path: "https://app.rasta.finance/pools",
  //   //       label: "Mr. Rasta",
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     label: "View More...",
  //     path: "#",
  //     //   icon: (
  //     //     <IoChatboxEllipsesOutline
  //     //       className="inline-block"
  //     //       style={{ width: "32px", height: "32px" }}
  //     //     />
  //     //   ),
  //     parent: true,
  //     child: [
  //       // {
  //       //   path: 'https://t.me/rastafinance',
  //       //   label: 'Telegram',
  //       // },
  //       {
  //         path: "https://docs.rasta.finance",
  //         label: "Whitepaper",
  //       },
  //       {
  //         path: "https://coinmarketcap.com/currencies/rasta-finance/",
  //         label: "CoinMarketCap",
  //       },
  //       // {
  //       //   path: 'https://rastafinance.medium.com',
  //       //   label: 'Medium',
  //       // },
  //       // {
  //       //   path: "https://dappradar.com/binance-smart-chain/defi/rasta-finance",
  //       //   label: "Dapp Radar",
  //       // },
  //       // {
  //       //   path: "https://dex.guru/token/0xe3e8cc42da487d1116d26687856e9fb684817c52-bsc",
  //       //   label: "Chart",
  //       // },
  //       {
  //         path: "https://docs.zionroyal.com/faq/rasta-audit",
  //         label: "Audit",
  //       },
  //     ],
  //   },
  //   {
  //     label: "Contact",
  //     path: "https://app.rasta.finance/contact",
  //     //   icon: (
  //     //     <GoMail
  //     //       className="inline-block"
  //     //       style={{ width: "32px", height: "32px" }}
  //     //     />
  //     //   ),
  //     parent: false,
  //     child: [],
  //   },
  // ];

  // const socialMedia = [
  //   {
  //     name: "Telegram",
  //     icon: <FaTelegramPlane />,
  //     link: "https://t.me/zionlabscommunity",
  //   },
  //   {
  //     name: "Twitter",
  //     icon: <FaTwitter />,
  //     link: "https://www.twitter.com/RastaFinance",
  //   },
  //   {
  //     name: "Medium",
  //     icon: <FaMediumM />,
  //     link: "https:///rastafinance.medium.com",
  //   },
  //   {
  //     name: "Reddit",
  //     icon: <FaReddit />,
  //     link: "https://www.reddit.com/r/rastafinance",
  //   },
  // ];

  return (
    <div className="navbar text-white flex align-middle items-center md:flex-1">
      <span className="menu-bars text-2xl cursor-pointer">
        <FaAlignLeft onClick={showSidebar} className="md:hidden" />
      </span>
      <nav
        className={`h-full w-full top-0 py-6 px-8 bg-black z-50 fixed transition duration-1000 flex flex-col justify-between overflow-x-auto ${
          sidebar ? "left-0 ml-0" : "-left-full -ml-16"
        }`}
      >
        <span className="menu-bars absolute right-4 text-3xl text-white cursor-pointer">
          <FaRegWindowClose onClick={showSidebar} />
        </span>

        <LazyLoadImage
          src={ZLPLogo}
          alt="ZLPLogo"
          className="w-48 px-10 mx-auto"
          effect="blur"
        />

{/* <span className='text-heading uppercase font-bold text-3xl text-center'>Zion Royal </span> */}

        <ul className="menu-items text-white mt-0 text-2xl space-y-6 font-sans font-light">
          {menu.map((item, index) => {
            return (
              <MenuItem key={index} menu={item} showSidebar={showSidebar} />
            );
          })}
        </ul>

        <a href='https://www.zionlp.com/RaggadatCris/Melody_1/stake' rel='nofollow'>
            
            <div className='my-2 w-full text-center'>
              <span className='font-bold text-center'>Featured Artist</span>
            </div>

            <div className='w-full flex items-center justify-center'>
              <div className='border-1 mb-5 w-1/2' style={{
              boxShadow: '4px 4px 0px #F9B262',
              borderColor: '#E94D75'
            }}>

                <img src="https://api.zionlabs.info/profile/ktliwtnl17aunve_profile.jpeg" alt='raggadat'/>

                <div className='w-full p-3'>
                  <span className='text-center w-full'>Raggadat Cris</span>
                </div>
              </div>
            </div>
          </a>

        <div>
          {/* <div className="social-media text-center justify-items-end p-4">
            <div className="md:flex md:flex-row md:space-x-4 gap-4 md:gap-0 mt-4 justify-center">
              {socialMedia.map((item, index) => {
                return (
                  <a
                    className=" inline-block md:block mr-5 last:mr-0 md:mr-0 text-md w-50 h-50 p-2 md:p-0 hover:text-green-rasta"
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.icon}
                  </a>
                );
              })}
            </div>
          </div> */}
          {/* <div className="flex items-center">
            <img
              src={RastaIcon}
              alt="token icon"
              width="20"
              className="rounded-full mr-2"
            />
            <span className="font-bold">
              ${Math.round(rastaPriceUsd * 1000) / 1000}
            </span>
          </div>
          <div className="flex items-center mt-2">
            <img
              src={MRastaIcon}
              alt="token icon"
              width="20"
              className="rounded-full mr-2"
            />
            <span className="font-bold">
              ${Math.round(mRastaPriceUsd * 1000) / 1000}
            </span>
          </div> */}
        </div>
      </nav>
      <LazyLoadImage
        src={ZLPLogo}
        alt="ZLPLogo"
        className="w-48 ml-5 hidden"
        effect="blur"
      />
    </div>
  );
}
