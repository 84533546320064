import React, { useEffect, useMemo, useState } from 'react'
import * as FaIcons from "react-icons/fa"
import useBuyNFT from '../../../../hooks/useBuyNFT'
import PulseLoader from "react-spinners/PulseLoader";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { useHistory } from "react-router-dom";
import { useUserInfo } from '../../../../hooks/usePool';

export default function BuyNow({ item }) {
    const [number, setNumber] = useState(1)
    const [loading, setLoading] = useState(false)
    const { onfetchUserInfo } = useUserInfo()
    const [userInfo, setUserInfo] = useState({
        mrastaBalance: 0
    })
    const { account } = useWallet()
    const history = useHistory()
    const isOwn = useMemo(() => {
        return item && (item.artist === account ? true : false)
    }, [item, account])

    useEffect(() => {
        (async () => {
            const userInfo = await onfetchUserInfo();
            if (userInfo) {
                setUserInfo(userInfo)
            }
        })()
    }, [account, onfetchUserInfo])

    const minHandler = () => {
        if (Number(number) > 1)
            setNumber(Number(number) - 1)
    }
    const plusHandler = () => {
        if (Number(number) < item.supply)
            setNumber(Number(number) + 1)
    }

    const { onBuy } = useBuyNFT();

    const buy = async () => {
        if (item.description && !loading && Number(number) > 0 && Number(number) <= Number(item.supply)) {
            setLoading(true);
            try {
                const tx = await onBuy(item.id, item.price, number);
                if (tx.status === true) {
                    NotificationManager.success('Transaction successed', '', 3000);
                    history.push(`/stake/${item.id}`);
                } else {
                    NotificationManager.warning('Transaction failed', '', 3000);
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
    }

    return (
        <div className="flex flex-col items-center w-full md:items-baseline space-y-4 md:space-y-8">
            <div className="w-2/3 amount flex flex-col space-y-2">
                {
                    Number(item.supply) === 0 ? 
                    <label className='flex items-center text-red-rasta'>SOLD OUT</label>
                    :
                    <label className='flex items-center gap-1'>Buy Amount <span className='text-sm text-red-rasta'>( max : {item.supply || 0} )</span></label>
                }
                <div className="flex flex-row">
                    <div className={"wrapper border-1 border-gray-400 rounded-md w-full md:w-auto flex justify-around" + (Number(number) > 0 && Number(number) <= Number(item.supply) ? "" : " border-red-500")}>
                        <button className="min px-3 text-2xl text-green-rasta" type="button" onClick={minHandler}>--</button>
                        <input type="number" className="border-0  text-2xl focus:border-opacity-0 w-12 text-center" id="qty" name="qty" value={number} onChange={e => setNumber(e.target.value)} />
                        <button className="plus px-3  text-2xl text-green-rasta" type="button" onClick={plusHandler}>+</button>
                    </div>
                </div>
            </div>
            <div className="w-2/3 md:w-full button flex flex-col md:flex-row flex-grow-1 space-y-4 md:space-y-0 space-x-0 md:space-x-4">
                {/* <button
                    disabled={item.id && account && !isOwn && Number(userInfo.mrastaBalance) >= Number(item.price) ? false : true}
                    onClick={buy}
                    className={(item.id && account && !isOwn && Number(userInfo.mrastaBalance) >= Number(item.price) ? '' : "disabled:cursor-not-allowed disabled:opacity-50") + " flex rounded-lg bg-gradient-to-l from-yellow-rasta to-green-rasta px-3 md:px-16 py-2 text-white justify-center"}
                > */}
                <button
                    disabled={item.id && account && !isOwn && Number(userInfo.mrastaBalance) >= Number(item.price) ? false : true}
                    onClick={buy}
                    className={(item.id && account && !isOwn && Number(userInfo.mrastaBalance) >= Number(item.price) ? '' : "disabled:cursor-not-allowed disabled:opacity-50") + " flex rounded-lg bg-gradient-to-b from-blue-zion to-blue-zion_cyan px-3 md:px-16 py-2 text-white justify-center"}
                >
                    {
                        loading ?
                            <PulseLoader size={10} color="white" />
                            :
                            <div className='space-x-2 flex flex-row items-center'>
                                <span className="icon"><FaIcons.FaWallet /></span>
                                <span className="text">BUY NOW</span>
                            </div>
                    }
                </button>
                <a href={`https://swap.zionlabs.info/#/swap?inputCurrency=0xe3e8cc42da487d1116d26687856e9fb684817c52&outputCurrency=0xeaa4a2469a8471bd8314b2ff63c1d113fe8114ba`} target="_blank" rel="noreferrer" className="flex rounded-lg flex-row text-black bg-gradient-to-b shadow-md border-1 hover:text-white border-gray-300 hover:bg-gradient-to-l hover:from-blue-zion hover:to-blue-zion_cyan px-3 md:px-16 py-2 items-center space-x-2 justify-center">
                    <span className="icon"><FaIcons.FaDollarSign /></span>
                    <span className="text">BUY MRASTA</span>
                </a>
            </div>
            <NotificationContainer />
        </div>
    )
}
