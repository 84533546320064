import React, { useRef, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import 'react-notifications/lib/notifications.css';
import Dropzone from "react-dropzone-uploader";
import PulseLoader from "react-spinners/PulseLoader";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { FaUpload } from "react-icons/fa";
import API from "../../../utils/api";
import { useArtists } from "../../../hooks/useAllNFT";
import { useDispatch } from "react-redux";
import { music_store } from "../../../redux/actions";

export default function Upload({ tabs, artist }) {
  const [loading, setLoading] = useState(false)
  const audioDropzone = useRef()
  const artists = useArtists()
  const dispatch = useDispatch()

  const _handleUpload = async () => {
    if (!artists) return;
    if (artists.indexOf(artist) === -1) return;
    const { current } = audioDropzone;
    if (!current.files.length) return NotificationManager.warning('Please select file', '', 3000);

    setLoading(true)
    const Form = new FormData();
    Form.append('file', current.files[0].file);
    Form.append('artist', artist);
    var audiod = current.files[0].meta.duration;
    var minutes = "0" + Math.floor(audiod / 60);
    var seconds = Math.floor(audiod - minutes * 60);
    var songTime = minutes + " : " + seconds;
    Form.append('duration', songTime);

    try {
      const { data } = await API('post', 'upload-music', Form)
      dispatch(music_store(data));
      current.files[0].remove();
      setLoading(false)
    } catch (error) {
      console.error(error)
      NotificationManager.error('Network error!', '', 3000);
      setLoading(false)
    }
  };


  return (
    <div className={(tabs === 'uploader' ? 'flex ' : 'hidden md:flex ') + "upload w-full md:w-8/12 h-52 flex-col"}>
      <Dropzone
        ref={audioDropzone}
        maxFiles={1}
        multiple={false}
        accept="audio/*"
        canCancel={false}
        inputContent={(files, extra) => (
          <div className="flex justify-center items-center flex-col gap-3" key={files.length}>
            <FaUpload size={60} />
            <span className="text-sm md:text-xl">Drag files to uploador Browse</span>
          </div>
        )}
        styles={{
          dropzoneActive: { borderColor: 'green' },
        }}
      />
      <div className="w-full flex justify-center mt-10">
        <button
          onClick={_handleUpload}
          className="w-1/2 md:w-1/4 flex flex-row text-white py-2 bg-gradient-to-r from-yellow-rasta to-green-rasta items-center justify-center space-x-4 text-md md:text-xl rounded-md cursor-pointer"
        >
          {
            loading ?
              <PulseLoader size={10} color="white" />
              :
              <span>Upload</span>
          }
        </button>
      </div>
      <NotificationContainer />
    </div>
  );
}
