import React, { useEffect } from "react";
import Banner from "./section/Banner";
import Body from "./section/Body";
import DataBank from "../DataBank";
// import ComingSoonImage from "../../assets/homebanner.png"
// import ComingSoonImage from "../../assets/new-homebanner.jpg";
import ComingSoonImage from "../../assets/newimage/music_nft.jpg";
import usePool from "../../hooks/usePool";

export default function Homepage() {
  const { onfetch } = usePool();

  useEffect(() => {
    onfetch();
  }, [onfetch]);

  const data = DataBank.homepage;
  return (
    <div className="pb-6 home-container">
      <Banner image={ComingSoonImage} content={data} />
      <Body />
    </div>
  );
}
