import React, { useState } from 'react'
import DescriptionTab from './Elements/DescriptionTab'
import AboutTab from './Elements/AboutTab'

export default function ProfileTab({ nft, artist }) {
    const [tabs, setTabs] = useState('desc')

    return (
        <div className="flex flex-col tabs__profile space-y-8 md:space-y-10 w-full px-8 md:px-0">
            <div className="flex justify-center md:justify-start tab__title flex-flex-row space-x-4">
                <button className={(tabs === 'desc' ? "text-black border-b-2 border-black font-bold text-lg" : "text-gray-500 text-lg")} onClick={() => setTabs('desc')} >Description</button>
                <button className={(tabs === 'about' ? "text-black border-b-2 border-black font-bold text-lg" : "text-gray-500 text-lg")} onClick={() => setTabs('about')}>About Artist</button>
            </div>

            <div className="content">
                {tabs === 'desc' ? <DescriptionTab desc={nft.description || ''} /> : <AboutTab artist={artist} />}
            </div>
        </div>
    )
}
