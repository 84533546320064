import { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import Header from "./components/global/Header";
import Footer from "./components/global/Footer";
import Home from "./components/home/Homepage";
import Create from "./components/mint/mint";
import StakePage from "./components/stake"
import 'react-dropzone-uploader/dist/styles.css'
import DetailSong from './components/detailSong/index'
import ArtistLaunchpad from "./components/artistsLaunchpad/ArtistLaunchpad"
import OwnPage from "./components/ownPage/"
import ArtistPage from "./components/Artist/"
import Login from "./components/login/Login"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewDesktopSidebar from './components/global/NewDesktopSidebar';

const ScrollToTop = () => {
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== 'POP') {
        document.querySelector("body").scrollTo(0,0);
      }
    })
    return () => unlisten()
  }, [history])
  return (null)
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="bg-white font-roboto h-screen text-black">
        <aside className="hidden md:block">
          <NewDesktopSidebar/>
        </aside>
        <div className="flex-1 md:ml-48 overflow-x-hidden">
          <Header />
          <Switch>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/create" component={Create}></Route>
            <Route exact path="/list/:type/:artist_name" component={OwnPage}></Route>
            <Route exact path="/artist-profile" component={ArtistPage}></Route>
            <Route exact path="/artist-profile/:artAddr" component={ArtistPage}></Route>
            <Route exact path="/launchpad-artists" component={ArtistLaunchpad} />
            <Route path="/:artist_name/:token_name/stake" component={StakePage} />
            <Route path="/:artist_name/:token_name" component={DetailSong} />
          </Switch>
          <Footer />
        </div>
        
      </div>
    </Router>
  );
}

export default App;
