import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ShopSong from "./section/ShopSong";
import ProfileTab from "./section/ProfileTab";
import MoreArtist from "./section/MoreArtist";
import LaunchpadArtist from "./section/LaunchpadArtist";
import SupportArtist from "./section/SupportArtist";
import { useParams } from "react-router-dom";
import { Raggadat_NFT } from "../../config/config";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function DetailSong() {
  const { prenfts, pool } = useSelector((store) => store.pool);
  const { token_name } = useParams();
  const tokenId = useMemo(() => {
    const _t = token_name.split("_");
    return _t[_t.length - 1];
  }, [token_name]);

  const [nft, setNft] = useState({});

  useEffect(() => {
    const items = prenfts || [];

    (async () => {
      try {
        for (let i = 0; i < items.length; i++) {
          if (items[i].tokenId === tokenId) {
            const uri = Number(items[i].tokenId) < 4 ? `${Raggadat_NFT[i]}` : items[i].uri;
            const res = await fetch(uri);
            const json = await res.json();
            setNft({
              ...json,
              id: items[i].tokenId,
              url: json.url,
            });
          }
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [tokenId, prenfts]);

  const artist = useMemo(() => {
    return pool?.artistsInfo?.find((item) => item.address === nft.artist) || {};
  }, [pool, nft]);

  const location = useLocation()

  let url = location.pathname
  let reUrl = url.split('/')
  let pagename = reUrl[1]
  let song = reUrl[2]

  return (
    <div className="md:max-w-screen-lg mx-auto bg-white text-black pb-20 md:pb-32 flex space-y-12 flex-col detail-container">

  <div className='breadcrumb text-gray-500 mt-10'>
      <Link to='/launchpad-artists'>Artist NFTs</Link>
        &nbsp; &gt; &nbsp;
        <Link to='/'>Launchpad Artist</Link>
        &nbsp; &gt; &nbsp;
        <Link to={location.pathname} className={location.pathname && 'underline'}>{pagename} - {song}</Link>
    </div>

      <div className="shadow-lg rounded-md p-10 space-y-12 mt-5 md:mt-10">
        <ShopSong nft={nft} artist={artist || {}} />
        <ProfileTab nft={nft} artist={artist || {}} />
        <SupportArtist item={nft} artist={artist || {}} />
      </div>

      <div className="shadow-lg rounded-md p-10 space-y-12">
        <MoreArtist items={prenfts} artist={artist || {}} />
      </div>

      <div className="shadow-lg rounded-md p-10 space-y-12">
        <LaunchpadArtist pool={pool} />
      </div>
    </div>
  );
}
