import React, { useEffect } from "react";
import usePool from "../../hooks/usePool";
import Stake from "./Stake";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import MoreArtist from "./section/MoreArtist";

export default function Detail() {
  const { onfetch } = usePool();
  const { account } = useWallet();

  useEffect(() => {
    onfetch();
  }, [account, onfetch]);

  return (
    <div className="wrapper bg-black profile-container">
      <Stake />
      
      <div className="flex container mx-auto md:pt-5 md:pb-10  ">
        <div className="w-full">
          <MoreArtist />
        </div>
      </div>
    </div>
  );
}
