import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'

export const useRastaPrice = () => {
  const [rastaPrice, setPrice] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/pairs/bsc/0x950b7377695e81235397da1b608c2087bc7002dc`)
        const { pair } = await response.json()
        return setPrice(new BigNumber(pair?.priceUsd || 0).toNumber());
      } catch (error) {
        return setPrice(0);
      }
    })()
  }, [setPrice])

  return rastaPrice;
}

export default useRastaPrice
