import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as IoIcons from "react-icons/io";

export default function MenuItem({ menu, showSidebar }) {
  const [children, setChildren] = useState(false);
  const showChildren = (isParent) => {
    if (isParent) setChildren(!children);
    else showSidebar(false);
  };
  
  if (menu.hidden) {
    return <React.Fragment />;
  }

  return (
    <li key={menu.label}>
      {menu.path.includes("http") ? (
        <a
          href={menu.path}
          className="flex flex-row space-x-4 items-center"
          onClick={() => showChildren(menu.parent)}
          target="_blank"
          rel="noreferrer nofollow"
        >
          {menu.icon}
          <span
            className="flex-grow-1 pr-0 text-left"
            style={{ fontSize: "16px" }}
          >
            {menu.label}
            <h1 style={{ fontSize: "13px" }}>{menu.sublabel}</h1>
          </span>
        </a>
      ) : (
        <Link
          to={menu.path}
          className="flex flex-row space-x-0 items-center"
          onClick={() => showChildren(menu.parent)}
        >
          {menu.icon}
          <span
            className="flex-grow-1 pr-4 text-left"
            style={{ fontSize: "16px" }}
          >
            {menu.label}
            <h1 style={{ fontSize: "13px" }}>{menu.sublabel}</h1>
          </span>
          {menu.parent && children && (
            <IoIcons.IoIosArrowDropdown style={{ fontSize: "18px" }} />
          )}
          {menu.parent && !children && (
            <IoIcons.IoIosArrowDropright style={{ fontSize: "18px" }} />
          )}
        </Link>
      )}
      {menu.child.length > 0 && children && (
        <ul className="flex flex-col items-start text-sm space-y-4 font-normal mt-4">
          {menu.child.map((i, id) => {
            if (!i.hidden) {
              return (
                <li key={i.label} className="pl-3">
                  {i.path.includes("http") ? (
                    <a href={i.path} onClick={showSidebar} rel="nofollow">
                      <span
                        className="ml-10 text-left"
                        style={{ fontSize: "16px", marginLeft: "0rem" }}
                      >
                        {i.label}
                      </span>
                    </a>
                  ) : (
                    <Link to={i.path} onClick={showSidebar}>
                      <span
                        className="ml-10 text-left"
                        style={{ fontSize: "16px", marginLeft: "0rem" }}
                      >
                        {i.label}
                      </span>
                    </Link>
                  )}
                </li>
              );
            } else {
              return <div className="hidden" key={i.label}></div>;
            }
          })}
        </ul>
      )}
    </li>
  );
}
