import React from 'react'
import * as FaIcons from "react-icons/fa";
import Skeleton from 'react-loading-skeleton';

export default function RastaInfo(props) {
  return (
    <div>
      <div className="row flex flex-col md:flex-row space-y-4 md:gap-16 mb-6 md:mb-12">
        <div className="w-full md:w-1/2 space-y-2 items-detail flex flex-col flex-grow-1">
          <div className="flex items-center core p-1 text-left gap-4 justify-center">

            {
              props.farmName ?
                <h2 className="text-xl font-bold text-left text-white">{props.farmName}</h2>
                :
                <Skeleton height={30} baseColor="#e2e8f0" />
            }
          </div>
          <div className="coin-info flex items-center justify-center space-x-2">
            <div className="core items-center flex flex-row space-x-2 border-2 text-white font-bold rounded-lg py-1 px-3">
              <FaIcons.FaCheckCircle className="fill-current text-white my-auto text-lg" />
              <span className="text-xs text-white">CORE</span>
            </div>
            {/* <div className="bg-orange-rasta rounded-full w-8 h-8  ">
              <img
                src={props.farmWallet}
                className="h-8 rounded-full"
                alt="logo"
              />
            </div> */}
          </div>
        </div>

        <div className='flex flex-row space-x-3'>
          <div className="apr text-center w-full md:w-1/2 px-6 py-3 flex flex-col justify-center border-1 border-white">
            <span className="apr-value text-md w-full text-white ">
              {Number(props.farmApr) ? `${Number(props.farmApr).toFixed()}%` : '-'}
            </span>
            <span className="apr-label text-text-white text-xs text-white">APR</span>
          </div>
          <div className=" w-full md:w-1/2 md:max-w-xs px-6 py-3 text-center flex flex-col justify-center text-white border-1 border-white" >
            <span className="apr-value text-md w-full text-white ">
              {0}%
            </span>
            <span className="apr-label text-white text-xs">Deposit Fee</span>
          </div>
        </div>
      </div>
    </div>
  )
}
