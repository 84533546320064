const Config = (
    state,
    action
) => {
    switch (action.type) {
        case 'STOREPOOL': {
            return { ...state, pool: { ...state?.pool, ...action.payload } };
        }
        case 'PRENFTSTORE': {
            return { ...state, prenfts: action.payload };
        }
        default: {
            return { ...state };
        }
    }
};
export default Config;
