import React from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CurrentLaunchpad from "./launchpad/CurrentLaunchpad";
// import ArtistsLaunchpad from "./launchpad/ArtistsLaunchpad"

export default function Body() {
  // const launchpadArtistItems = props.artistLaunchpad
  const location = useLocation()

  return (
    <div className="bg-white py-16" style={{ minHeight: 200 }}>

      <div className="wrapper max-w-screen-lg mx-auto space-y-10">
      
        <div className='breadcrumb text-gray-500'>
          <Link to='/' className="underline">Artist NFTs</Link>
          &nbsp; &gt; &nbsp;
        <Link to='/launchpad-artists' className={location.pathname && ''}>Launchpad Artist</Link>
        </div>

        <CurrentLaunchpad />
        {/* {
          launchpadArtistItems.length ? <ArtistsLaunchpad items={launchpadArtistItems} /> : null
        } */}
        
        {/* <div className="title-bar flex-grow-1">
          <h2 className="text-lg md:text-2xl font-bold">Prince Raven</h2>
        </div>

        <div className="title-bar flex-grow-1">
          <h2 className="text-lg md:text-2xl font-bold">Mr Luu</h2>
        </div>

        <div className="title-bar flex-grow-1">
          <h2 className="text-lg md:text-2xl font-bold">K Queens</h2>
        </div> */}

      </div>
    </div>
  );
}
