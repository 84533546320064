import React, { useCallback, useEffect, useState } from "react";
import RastaInfo from "./CardElements/RastaInfo";
import FarmHarvest from "./CardElements/FarmHarvest";
import Wallet from "./CardElements/Wallet";
import FooterCardFarms from "./CardElements/FooterCardFarms";
import MrastaLogo from "../../../../../assets/menu_coin1.png";
import BigNumber from 'bignumber.js'
import { PER_SEC_YEAR } from '../../../../../config/config';
import styled from 'styled-components'
import { useFarmInfo, useUserInfo } from "../../../../../hooks/usePool";

const Card = styled.div`
  @media (max-width: 600px){
    width : 100% !important
  }
`

export default function CardSection({ nft }) {
  const [farmInfo, setFarmInfo] = useState();
  const [userInfo, setUserInfo] = useState({
    pendingRewards: 0,
    userStatedTokenIds: [],
    approved: false,
    mrastaBalance: 0,
    purchasedNfts: [],
    stakedAmount: 0,
    depositedAt: new Date().getTime() / 1000
  })
  const { onFetchFarmInfo } = useFarmInfo();
  const { onfetchUserInfo } = useUserInfo()

  const _handleFetch = useCallback(async () => {
    const _userInfo = await onfetchUserInfo();
    const _farmInfo = await onFetchFarmInfo();
    setFarmInfo(_farmInfo);

    if (_userInfo) {
      setUserInfo(_userInfo);
    }
  }, [onfetchUserInfo, onFetchFarmInfo])

  useEffect(() => {
    _handleFetch()

    const tracker = setInterval(_handleFetch, 60000)

    return () => {
      clearInterval(tracker);
    }

  }, [_handleFetch])

  const apr = new BigNumber(farmInfo ? farmInfo.rewardRate : 0)
    .times(PER_SEC_YEAR)
    .div(farmInfo && (Number(farmInfo.totalSupply) || new BigNumber(10).pow(18)))
    .times(60)
    .toNumber().toFixed(0)

  const item = {
    name: "M - RASTA 1",
    detail: {
      core: "Core",
      multiple: "35x",
      wallet: MrastaLogo,
      apr: "256.08",
      depositFee: "0",
      rastaEarned: "0",
      harvesLink: "/",
      unlockWalletLink: "/",
      stake: "Total Liquidity",
      value: "47,581",
      bscLink: "/",
    },
  };

  return (
    <Card className="wrapper flex flex-col items-center flex-grow-1 w-full">
      <div className="flex flex-col mx-2 w-full">
        <div className=" shadow-outerWhite p-8 border-1 border-white">
          <RastaInfo
            farmName={nft.name}
            farmLeverage={item.detail.multiple}
            farmWallet={item.detail.wallet}
            farmApr={apr}
          />
          <div className={" expanded md:block"}>
            <FarmHarvest
              nft={nft}
              farmApr={apr}
              userInfo={userInfo}
              onFetch={_handleFetch}
              totalValue={farmInfo?.totalSupply}
              stakedValue={userInfo.stakedAmount}
            />
            <Wallet
              nft={nft}
              userInfo={userInfo}
              onFetch={_handleFetch}
            />
            {/* <FooterCardFarms
              nft={nft}
              totalValue={farmInfo?.totalSupply}
              stakedValue={userInfo.stakedAmount}
              farmBscLink={item.detail.bscLink}
            /> */}
          </div>
        </div>
      </div>
    </Card>
  );
}
