import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import ClipLoader from "react-spinners/ClipLoader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import { useMobileStatus } from "../../../../../hooks/hook";
import Slider from "react-slick";
import { SERVER_URL } from "../../../../../utils/api";
import { useSelector } from "react-redux";
import { Raggadat_NFT } from "../../../../../config/config";

const MultipleSlick = () => {
  const { prenfts, pool } = useSelector((store) => store.pool);

  const items = prenfts || [];
  const isMobile = useMobileStatus();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="text-black nfts-container">
      {isMobile ? (
        <Swiper
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={1.7}
          effect={"coverflow"}
          modules={[EffectCoverflow]}
          coverflowEffect={{
            rotate: 0,
            stretch: -12,
            depth: 100,
            modifier: 5,
            slideShadows: true,
          }}
          className="w-11/12"
        >
          {items.map((item, index) => (
            <SwiperSlide style={{ padding: 5 }} key={index} >
              <MobileView artist={pool?.artistsInfo} item={item} i={index} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Slider {...settings}>
          {items.map((item, index) => (
            <DesktopView artist={pool?.artistsInfo} item={item} i={index} key={index} />
          ))}
        </Slider>
      )}
    </div>
  );
};

const MobileView = ({ item, artist, i }) => {
  const [data, setData] = useState({});
  const artistInfo = artist?.find(e => e.address === item.artist);

  useEffect(() => {
    (async () => {
      try {
        const uri = Number(item.tokenId) < 4 ? `${Raggadat_NFT[i]}` : item.uri;
        const res = await fetch(uri);
        const json = await res.json();
        setData({
          ...json,
          id: item.tokenId,
          url: json.url,
        });
      } catch (error) {
        console.log(error)
      }
    })()
  }, [item, i])

  return (
    <Link
      to={artistInfo && data.id ? `/${String(artistInfo?.name)?.replaceAll(" ", "")}/${data?.name}_${data?.id}` : "#"}
      className="card flex flex-col hover:shadow-box bg-white"
    >
      <div className="thumbnail" style={{ height: 190 }}>
        {data.description ? (
          <LazyLoadImage
            // src={data.url}
            src={`${SERVER_URL}videos/raggadat-cris-${data.id}.gif`}
            alt={data.name}
          />
        ) : (
          <ClipLoader color="#0006" />
        )}
      </div>
      <div className="song-detail my-4 px-4 flex flex-col space-y-2">
        {data.description ? (
          <div className="artist-name text-md font-bold">
            {data.name}
          </div>
        ) : (
          <Skeleton
            height={30}
            baseColor="#E7E9EB"
            highlightColor="white"
          />
        )}
        {data.description ? (
          <div
            className="artist-name text-sm text-blue-zion"
          // style={{ color: "red" }}
          >
            {data.price} MRASTA
          </div>
        ) : (
          <Skeleton
            height={30}
            baseColor="#E7E9EB"
            highlightColor="white"
          />
        )}
      </div>
    </Link>
  )
}

const DesktopView = ({ item, artist, i }) => {
  const [data, setData] = useState({});
  const artistInfo = artist?.find(e => e.address === item.artist);

  useEffect(() => {
    (async () => {
      try {
        const uri = Number(item.tokenId) < 4 ? `${Raggadat_NFT[i]}` : item.uri;
        const res = await fetch(uri);
        const json = await res.json();
        setData({
          ...json,
          id: item.tokenId,
          url: json.url,
        });
      } catch (error) {
        console.log(error)
      }
    })()
  }, [item, i])

  return (
    <Link
      to={artistInfo && data.id ? `/${String(artistInfo?.name)?.replaceAll(" ", "")}/${data?.name}_${data?.id}` : "#"}
      className="card flex flex-col gap-2 hover:shadow-box"
    >
      <div className="thumbnail">
        {data?.description ? (
          data.type === "video" && !data.preview ? (
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <source src={data.url} type="video/mp4" />
            </video>
          ) : (
            <LazyLoadImage
              src={data.url}
              alt={data.name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )
        ) : (
          <ClipLoader color="#0006" />
        )}
      </div>
      <div className="song-detail my-4 px-4 flex flex-col space-y-2">
        {data?.description ? (
          <div className="artist-name text-xl font-bold text-center md:text-left">
            {data.name}
          </div>
        ) : (
          <Skeleton
            height={30}
            baseColor="#E7E9EB"
            highlightColor="white"
          />
        )}
        {data?.description ? (
          // <div className="artist-name text-md" style={{ color: "red" }}>
          <div className="artist-name text-sm text-blue-zion">
            {data.price} MRASTA
          </div>
        ) : (
          <Skeleton
            height={30}
            baseColor="#E7E9EB"
            highlightColor="white"
          />
        )}
      </div>
    </Link>
  )
}

export default MultipleSlick;
