import React, { useCallback } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import BNBRastaIcon from "../../../assets/menu_coin1.png";
import BuyNow from './Elements/BuyNow';
import ClipLoader from "react-spinners/ClipLoader";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useMobileStatus } from "../../../hooks/hook";
import { SERVER_URL } from '../../../utils/api';



export default function ShopSong({ artist, nft }) {
    const isMobile = useMobileStatus()

    const MediaView = useCallback(() => {
        return (
            nft?.description ?
                nft.type === "video" && !nft.preview && !isMobile ? (
                    <video className='w-full h-full object-cover rounded-2xl' autoPlay loop>
                        <source src={nft.url} type="video/mp4" />
                    </video>
                ) : (
                    <LazyLoadImage src={`${SERVER_URL}videos/raggadat-cris-${nft.id}.gif`} alt={nft.name} className='w-full h-full object-cover rounded-2xl' />
                ) : <ClipLoader color="#0006" />
        )
    }, [nft, isMobile]);

    return (
        <div className="items-center section flex flex-col md:flex-row flex-grow-1 w-full pt-10 px-0 md:px-0 md:pt-2 md:space-x-16 space-y-5">
            <div className="detail-img thumbnail" style={{ background: nft?.description ? 'transparent' : '#E7E9EB' }}>
                <MediaView />
            </div>
            <div className="justify-center shopSection flex flex-col flex-grow-1 space-y-5 md:space-y-8 items-center md:items-baseline w-full md:w-auto">
                <div className='w-full space-y-2 flex flex-col items-center md:items-start'>
                    {
                        artist.name ?
                            <div className="title text-2xl md:text-3xl font-bold capitalize">
                                {artist.name || 'Unknown'} - {nft.name}
                            </div>
                            :
                            <Skeleton height={30} width={300} />
                    }
                    <div className="w-2/3 price flex flex-row items-center space-x-3 border-1 border-gray-300 shadow-md px-6 py-2 rounded-lg justify-between md:p-0 md:border-0 md:shadow-none md:justify-start md:w-full">
                        <span className="icon"><img src={BNBRastaIcon} className="w-6 md:w-8 rounded-full" alt="no data" /></span>
                        {
                            nft?.price ?
                                <div className="price text-xl md:text-2xl text-blue-zion font-bold">{nft.price} MRASTA</div>
                                :
                                <Skeleton height={30} width={isMobile ? 100 : 255} />
                        }
                    </div>
                </div>
                <BuyNow item={nft} />
            </div>
        </div>
    )
}
