import React, { useEffect, useMemo, useState } from "react";
import * as FaIcons from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/all"
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { useStake } from "../../../../../../hooks/useStake";
import PulseLoader from "react-spinners/PulseLoader";
import { NotificationContainer } from 'react-notifications';
import BinanceWallet from '../../../../../../assets/wallet/binance-wallet.png'
import MathWallet from '../../../../../../assets/wallet/math-wallet.png'
import MetaMask from '../../../../../../assets/wallet/meta-mask.png'
import TokenPocket from '../../../../../../assets/wallet/token-pocket.png'
import TrustWallet from '../../../../../../assets/wallet/trust-wallet.png'
import WalletConnect from '../../../../../../assets/wallet/wallet-connect.png'
import { Link } from "react-router-dom";

export default function Wallet({ nft, userInfo, onFetch }) {
  const [showModal, setShowModal] = useState(false);
  const [poolState, setPoolState] = useState()
  const [loading, setLoading] = useState(false)
  const { account, connect } = useWallet()
  const { onStake, onUnstake, onApprove, onUnstakeAll, onStakeAll } = useStake()

  const connectWallet = (what) => {
    setShowModal(false)
    if (what !== '') {
      connect(what)
    }
    else connect('injected')
  }
  const purchasedNft = useMemo(() => {
    return userInfo.purchasedNfts.find(e => e.tokenId === nft.id) || { balance: 0 };
  }, [nft, userInfo.purchasedNfts])

  useEffect(() => {
    if (nft.id && userInfo.userStatedTokenIds.length) {
      setPoolState(userInfo.userStatedTokenIds.find(e => e.tokenId === nft.id) ? true : false)
    }
  }, [userInfo, nft])

  const stake = async () => {
    if (!nft.id) return;
    setLoading(true)
    try {
      const tx = await onStake(nft.id);
      if (tx.status === true) {
        setPoolState(true)
      }
      setLoading(false)
      await onFetch()
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  }

  const stakeAll = async () => {
    setLoading(true)
    try {
      const tx = await onStakeAll();
      if (tx.status === true) {
        setPoolState(true)
      }
      setLoading(false)
      await onFetch()
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  }

  const approve = async () => {
    setLoading(true)
    try {
      await onApprove();
      setLoading(false)
      await onFetch();
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };


  const unstake = async () => {
    if (!nft.id) return;
    setLoading(true)
    try {
      const tx = await onUnstake(nft.id);
      if (tx.status === true) {
        setPoolState(false)
      }
      setLoading(false)
      await onFetch();
    } catch (error) {
      setLoading(false)
    }
  }

  const unstakeAll = async () => {
    setLoading(true)
    try {
      const tx = await onUnstakeAll();
      if (tx.status === true) {
        setPoolState(false)
      }
      setLoading(false)
      await onFetch();
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const wallet = [
    {
      name: 'Metamask',
      icon: MetaMask,
      link: 'injected',
    },
    {
      name: 'TrustWallet',
      icon: TrustWallet,
      link: 'injected',
    },
    {
      name: 'MathWallet',
      icon: MathWallet,
      link: 'injected',
    },
    {
      name: 'TokenPocket',
      icon: TokenPocket,
      link: 'injected',
    },
    {
      name: 'WalletConnect',
      icon: WalletConnect,
      link: 'walletconnect',
    },
    {
      name: 'Binance Chain',
      icon: BinanceWallet,
      link: 'bsc',
    },
  ]

  return (
    <div className="wallet">
      {(() => {
        if (account) {
          if (nft.description) {
            if (nft.owner === account) {
              if (!userInfo.approved) {
                return (
                  <div className="flex approved space-x-4">
                    <button
                      onClick={approve}
                      disabled={loading ? true : false}
                      className="unstake__btn text-xl border-1 border-white shadow-outerWhite py-2 flex-grow-1 rounded-md text-white">
                      {
                        loading ?
                          <PulseLoader size={10} color="white" />
                          :
                          <div className="flex items-center justify-center gap-2">
                            <FaIcons.FaCheck />
                            <span>APPROVE</span>
                          </div>
                      }
                    </button>
                  </div>
                )
              }
              if (poolState) {
                return (
                  <div className="flex flex-col approved gap-4">
                    <div className="flex flex-row approved gap-4">
                      {
                        Number(purchasedNft.balance) > 0 &&
                        <button
                          onClick={stake}
                          disabled={loading ? true : false}
                          className={(loading ? "disabled:cursor-not-allowed opacity-50 " : "") + "unstake__btn border-1 border-white py-2 flex-grow-1  text-white w-full"}>
                          <div className="flex items-center justify-center gap-2">
                            <FaIcons.FaHandHoldingUsd />
                            <span>STAKE MORE</span>
                          </div>
                        </button>
                      }
                      <button
                        onClick={unstake}
                        disabled={loading ? true : false}
                        className={(loading ? "disabled:cursor-not-allowed opacity-50 " : "") + "unstake__btn border-1 border-white py-2 flex-grow-1  text-white w-full"}>
                        <div className="flex items-center justify-center gap-2">
                          <FaIcons.FaDungeon />
                          <span>UNSTAKE</span>
                        </div>
                      </button>
                    </div>
                    <div className="flex gap-4">
                      {
                        Number(userInfo.purchasedNfts.length) > 0 &&
                        <button
                          onClick={stakeAll}
                          disabled={!loading ? false : true}
                          className={(!loading ? "" : "disabled:cursor-not-allowed opacity-50 ") + "unstake__btn border-1 border-white py-2 flex-grow-1 text-white  w-full"}>
                          <div className="flex items-center justify-center gap-2">
                            <GiReceiveMoney />
                            <span>STAKE All</span>
                          </div>
                        </button>
                      }
                      {
                        userInfo.userStatedTokenIds.length > 0 &&
                        <button
                          onClick={unstakeAll}
                          disabled={loading ? true : false}
                          className={(loading ? "disabled:cursor-not-allowed opacity-50 " : "") + "unstake__btn border-1 border-white py-2 flex-grow-1 text-white  w-full"}>
                          <div className="flex items-center justify-center gap-2">
                            <FaIcons.FaDungeon />
                            <span>UNSTAKE All</span>
                          </div>
                        </button>
                      }
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className="flex flex-col approved gap-4">
                    <button
                      onClick={stake}
                      disabled={loading ? true : false}
                      className={(loading ? "disabled:cursor-not-allowed opacity-50 " : "") + "unstake__btn border-1 border-white py-2 flex-grow-1  text-white"}>
                      <div className="flex items-center justify-center gap-2">
                        <FaIcons.FaHandHoldingUsd />
                        <span>STAKE</span>
                      </div>
                    </button>
                    <div className="flex gap-4">
                      {
                        Number(userInfo.purchasedNfts.length) > 0 &&
                        <button
                          onClick={stakeAll}
                          disabled={!loading ? false : true}
                          className={(!loading ? "" : "disabled:cursor-not-allowed opacity-50 ") + "unstake__btn border-1 border-whitepy-2 flex-grow-1 text-white  w-full"}>
                          <div className="flex items-center justify-center gap-2">
                            <GiReceiveMoney />
                            <span>STAKE All</span>
                          </div>
                        </button>
                      }
                      {
                        userInfo.userStatedTokenIds.length > 0 &&
                        <button
                          onClick={unstakeAll}
                          disabled={loading ? true : false}
                          className={(loading ? "disabled:cursor-not-allowed opacity-50 " : "") + "unstake__btn border-1 border-white py-2 flex-grow-1 text-white  w-full"}>
                          <div className="flex items-center justify-center gap-2">
                            <FaIcons.FaDungeon />
                            <span>UNSTAKE All</span>
                          </div>
                        </button>
                      }
                    </div>
                  </div>
                )
              }
            } else {
              return (
                <Link
                  to={`/detail/${nft.id}`}
                  disabled={loading && Number(userInfo.mrastaBalance) < Number(nft.price) && !nft.isListed ? true : false}
                  className={(Number(userInfo.mrastaBalance) < Number(nft.price) && !nft.isListed ? "disabled:cursor-not-allowed disabled:opacity-50" : '') + "w-full flex flex-row text-white py-2 border-1 border-white items-center justify-center space-x-4 text-xl "}
                >
                  {
                    loading ?
                      <PulseLoader size={10} color="white" />
                      :
                      <div className="flex items-center justify-center gap-2">
                        <FaIcons.FaShoppingBag />
                        <span>BUY NFT</span>
                      </div>
                  }
                </Link>
              )
            }
          } else {
            return (
              <button
                className="w-full flex flex-row text-white py-2 border-1 border-white items-center justify-center space-x-4 text-xl "
              >
                <PulseLoader size={10} color="white" />
              </button>
            )
          }
        } else {
          return (
            <button
              onClick={() => setShowModal(!showModal)}
              // className="w-full flex flex-row text-white py-3 bg-gradient-to-r from-yellow-rasta to-green-rasta items-center justify-center space-x-4 text-xl rounded-xl"
              className="w-full flex flex-row text-white py-3 border-1 border-white items-center justify-center space-x-4 text-xl"
            >
              <FaIcons.FaWallet />
              <span>Unlock Wallet</span>
            </button>
          )
        }
      })()}

      {showModal ? (
        <>
          <div className="text-white justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black">
            <div className="relative w-full md:w-1/3 my-6 mx-auto max-w-3xl">
              {/* content */}
              <div className=" shadow-outerWhite relative flex flex-col w-full border-1 border-white pb-8 outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-start justify-between p-5 border-1 border-white ">
                  <h3 className="text-3xl font-semibold">Connect to a Wallet</h3>
                  <button
                    type="button"
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-white opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex">
                  <div className="grid grid-cols-2 md:grid-cols-3 mx-auto gap-4">
                    {wallet.map((item, index) => {
                      return (
                        <span
                          className="wallet-wrap flex flex-col space-y-3 bg-gray-inBlack px-2 py-4 rounded-xl items-center cursor-pointer"
                          onClick={(e) => connectWallet(item.link)}
                          key={index}
                        >
                          <span>
                            <img src={item.icon} alt="icon" />
                          </span>
                          <span>{item.name}</span>
                        </span>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
      <NotificationContainer />
    </div>
  );
}
