import React from "react";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";

export default function SupportArtist({ item, artist }) {
  return (
    <div className="flex flex-col items-center space-y-4">
      <label>Want to Support the Artist?</label>
      {/* <Link to={item.id ? `/stake/${item.id}` : "#"} style={{ cursor: item.id ? "pointer" : "not-allowed" }} className="uppercase flex flex-row text-lg md:text-xl items-center space-x-5 px-12 rounded-md py-2 bg-gradient-to-r from-yellow-rasta to-green-rasta text-white"> */}
      <Link
        to={item.id ? `/${String(artist?.name)?.replaceAll(" ", "")}/${item?.name}_${item?.id}/stake` : "#"}
        style={{ cursor: item.id ? "pointer" : "not-allowed" }}
        className="uppercase flex flex-row text-lg md:text-xl items-center space-x-5 px-12 rounded-md py-2 bg-gradient-to-b from-blue-zion to-blue-zion_cyan text-white"
      >
        <div className="icon">
          <FaIcons.FaHandHoldingUsd className="text-2xl" />
        </div>
        {/* <div className="text">STAKE NFT </div> */}
        {/* <div className="text">STAKE on ZLP </div> */}
        <div className="text">Launchpad </div>
      </Link>
    </div>
  );
}
