import React from "react";

export default function Banner(props) {
  return (
    // <div
    //   className="bg-black hero-section py-12 md:py-18 flex md:items-start flex-col text-left text-white"
    //   style={{
    //     backgroundImage: "url(" + props.image + ")",
    //     backgroundSize: "cover",
    //     backgroundPosition: "center center",
    //     backgroundRepeat: "no-repeat",
    //   }}
    // >
    <div
      className="bg-black hero-section py-40 md:py-18 flex md:items-start flex-col text-left text-white"
      style={{
        backgroundImage: "url(" + props.image + ")",
        backgroundSize: "cover",
        backgroundPosition: "bottom center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="wrapper text-center md:text-left max-w-screen-lg md:pl-48 md:space-y-2">
        <div>
          {/* <h3 className="text-xl md:text-3xl font-bold">Explore our</h3> */}
          <h3 className="text-xl md:text-3xl font-bold">Zion Launchpad</h3>
        </div>
        <div>
          {/* <h1 className="text-3xl md:text-5xl font-bold">Artists NFTs</h1> */}
          <h1 className="text-3xl md:text-5xl font-bold">Music NFTs</h1>
        </div>
      </div>
    </div>
  );
}
