import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as FaIcons from "react-icons/fa";
import { Pagination, Scrollbar, A11y, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import DefaultProfile from "./../../../assets/purple.jpg";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import { SERVER_URL } from "../../../utils/api";
import { useMobileStatus } from "../../../hooks/hook";

export default function LaunchpadArtist({ pool }) {
  const isMobile = useMobileStatus()
  const artists = useMemo(() => {
    return pool ? pool.artistlists : [];
  }, [pool])

  const artistsInfo = useMemo(() => {
    return pool ? pool.artistsInfo : [];
  }, [pool])

  const songInfo = useMemo(() => {
    return pool ? pool.songInfo : [];
  }, [pool])

  return (
    <div className="current-launchpad flex flex-col space-y-8">
      <div className="header flex flex-col md:flex-row items-center">
        <div className="title-bar flex-grow-1">
          <h2 className="text-xl md:text-2xl font-bold">Launchpad Artists</h2>
        </div>
        <Link className="browse hidden md:flex flex-row items-center space-x-4" to={"/launchpad-artists"}>
          <span>Browse More</span>
          <span>
            <FaIcons.FaArrowRight />
          </span>
        </Link>
      </div>
      <div className="body">
        {
          isMobile ? (
            <Swiper
              grabCursor={true}
              centeredSlides={false}
              slidesPerView={2.2}
              effect={"coverflow"}
              modules={[EffectCoverflow]}
              coverflowEffect={{
                rotate: 0,
                stretch: -17,
                depth: 100,
                modifier: 1,
                slideShadows: false
              }}
              className="w-11/12"
            >
              {
                artists && artists.map((item, index) => {
                  let artist = artistsInfo?.find(art => art.address === item) || {};
                  let music = songInfo && songInfo.find(mus => mus.artist === item);
                  music = music || {}
                  return <SwiperSlide key={index} style={{ padding: 5 }}>
                    <Link
                      to={artist.name ? `/list/artist/${String(artist.name).replaceAll(" ", "")}` : "#"}
                      className="card current__launchpad__item flex flex-col hover:shadow-box bg-white"
                    >
                      <div className="thumbnail w-full" style={{ height: 160 }}>
                        <LazyLoadImage src={artist.avatar_url ? `${SERVER_URL}profile/${artist.avatar_url}` : DefaultProfile} className="object-cover h-full w-full" alt={item} />
                      </div>
                      <div className="song-detail my-4 px-4 flex flex-col space-y-2">
                        {
                          <div className="artist-name text-md font-bold">
                            {artist.name || 'Unknown'}
                          </div>
                        }
                        {
                          // <div className="artist-name text-sm" style={{ color: "red" }}>
                          <div className="artist-name text-sm text-blue-zion">
                            {music.title || 'Unknown'}
                          </div>
                        }
                      </div>
                    </Link>
                  </SwiperSlide>
                })
              }
            </Swiper>
          ) : (
            <Swiper
              modules={[Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={4}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {
                artists && artists.map((item, index) => {
                  let artist = artistsInfo?.find(art => art.address === item) || {};
                  let music = songInfo && songInfo.find(mus => mus.artist === item);
                  music = music || {}
                  return <SwiperSlide key={index} style={{ padding: 5 }}>
                    <Link
                      to={artist.name ? `/list/artist/${String(artist.name).replaceAll(" ", "")}` : "#"}
                      className="card current__launchpad__item flex flex-col gap-2 hover:shadow-box"
                    >
                      <div className="thumbnail more-artists-img">
                        <LazyLoadImage src={artist.avatar_url ? `${SERVER_URL}profile/${artist.avatar_url}` : DefaultProfile} className="w-full h-full object-cover" alt={item} />
                      </div>
                      <div className="song-detail my-4 px-4 flex flex-col space-y-2">
                        {
                          <div className="artist-name text-xl font-bold text-center md:text-left">
                            {artist.name || 'Unknown'}
                          </div>
                        }
                        {
                          // <div className="artist-name text-md" style={{ color: "red" }}>
                          <div className="artist-name text-sm text-blue-zion">
                            {music.title || 'Unknown'}
                          </div>
                        }
                      </div>
                    </Link>
                  </SwiperSlide>
                })
              }
            </Swiper>
          )
        }
      </div>
    </div>
  );
}
