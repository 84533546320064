import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import profileHeader from "./../../../assets/profile-header.jpg";
import profileHeader from "./../../../assets/profile-header-blue.jpg";
import ClipLoader from "react-spinners/ClipLoader";
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import Player from "./Player";
import { SERVER_URL } from '../../../utils/api';
import { useMobileStatus } from "../../../hooks/hook";
import ProfileSlider from "../../elements/slickSlide/ProfileSlide";
import SliderImage from "../../../assets/profile/Profile-slider-1.jpg";

const CustomHeader = styled.div`
  @media (min-width: 1600px){
  }
`
export default function Header({ nft, toggle, playing, handleNext, handlePrevious, artist, intro }) {
  const isMobile = useMobileStatus()

  const Slider = {
    'artistInfo' : {
      'title' : "Raggadat ?",
      'description': "Lorem Ipsum dolor sit amet"
    },
    'image' : [ <SliderImage/>, <SliderImage/> ]
  }
  return (
    <div
      className="flex w-full flex-col items-center md:flex-row bg-cover bg-black text-white "
    >
    <div className="slider w-full md:w-9/12">
      <ProfileSlider items={Slider}/>
    </div>
      <CustomHeader className="items-center mt-4 md:mt-0 md:items-center mx-auto flex flex-col md:flex-col w-full px-8 md:px-16 md:w-3/12 space-y-2 gap-5">
        <div
          className="profile w-40 detail-img"
          style={{ background: nft.description ? 'none' : '#E7E9EB' }}
        >
          {
            nft.description ?
              nft.type === "video" && !nft.preview && !isMobile ? (
                <video className='w-full object-cover' autoPlay loop>
                  <source src={nft.url} type="video/mp4" />
                </video>
              ) : (
                <LazyLoadImage src={`${SERVER_URL}videos/raggadat-cris-${nft.id}.gif`} alt={nft.name} className={`w-24 object-cover`} />
              ) : <ClipLoader color="#0006" />
          }
        </div>
        <div className="music-play flex flex-col space-y-2 md:px-8 w-full md:w-4/5 items-center md:items-center">
        <div className="title flex flex-col">
            {
              nft.description ?
              <div className="song-detail text-center">
                <h2 className="w-full text-md text-center md:text-left md:text-md font-normal text-white capitalize">
                  {artist.name || 'Unknown'}
                </h2>
                <p className="font-bold">{intro.title || 'New Upload'}</p>
                </div>
                :
                <Skeleton height={40} width={300} baseColor="#dd6119" highlightColor="#e57915" />
            }
          </div>
          <Player playing={playing} toggle={toggle} handleNext={handleNext} handlePrevious={handlePrevious} />
        </div>
        <div className="purchase-box shadow-outerWhite w-full border-1 border-white flex flex-col px-4 py-6">
            <div className="info-box flex flex-row content-between justify-between items-center ">
              <div className="title-name">
                <div className="title">{artist.name || 'Unknown'}</div>
                <p className="font-bold">{intro.title || 'New Upload'}</p>
              </div>
              <div className="price">30 MRASTA</div>
             
            </div>
            <div className="button w-full justify-between flex flex-row mt-4">
                <a href="#" className=" py-2 flex-row space-x-2 flex px-4 items-center justify-center text-black bg-white">Buy Now</a>
                <a href="#" className=" py-2 flex-row space-x-2 flex px-4 items-center justify-center text-white border-1 border-white">Buy MRasta</a>
              </div>
        </div>
      </CustomHeader>
    </div>
  );
}
