import { useCallback } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ApprovalForAll, stake, stakeAll, unstake, unstakeAll } from '../utils/callHelpers'
import { useNftContract, useStakeContract } from './useContract'
import Contracts from '../config/constants/constants'

export const useStake = () => {
    const { account } = useWallet()
    const poolContract = useStakeContract()
    const nftContract = useNftContract()

    const _handleApprove = useCallback(async () => {
        if (account) {
            return await ApprovalForAll(nftContract, Contracts.stake.address, account);
        } else {
            return {};
        }
    }, [nftContract, account])

    const _handleStake = useCallback(
        async (tokenId) => {
            if (account) {
                return await stake(poolContract, tokenId, account);
            } else {
                return {};
            }
        },
        [account, poolContract],
    )

    const _handleStakeAll = useCallback(
        async () => {
            if (account) {
                return await stakeAll(poolContract, account);
            } else {
                return {};
            }
        },
        [account, poolContract],
    )

    const _handleUnstake = useCallback(
        async (tokenId) => {
            if (account) {
                return await unstake(poolContract, tokenId, account);
            } else {
                return {};
            }
        },
        [account, poolContract],
    )

    const _handleUnstakeAll = useCallback(
        async () => {
            if (account) {
                return await unstakeAll(poolContract, account);
            } else {
                return {};
            }
        },
        [account, poolContract],
    )

    return { onStake: _handleStake, onUnstake: _handleUnstake, onApprove: _handleApprove, onStakeAll: _handleStakeAll, onUnstakeAll: _handleUnstakeAll }
}