import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import './profileSlide.css';

export default class MultipleSlick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artist : props.items.artistInfo,
      sliders: props.items.image,
    };
  }

  sliders() {
    return this.state.sliders.map((item, index) => {
      return (
        <div
          key={index}
          className="flex-imp flex-col w-full shadow-md relative"
        >
          <div className="w-full image-overlay flex flex-col justify-end min-h-full" style={
            {
              backgroundImage: `url(${item.type})`,
              backgroundSize: "cover",
              backgroundPosition: "top center",
              minHeight:"550px",
            }
          }>
            <div className="info flex flex-col px-6 py-8">
              <h1 className="title text-white text-2xl font-bold">{this.state.artist.title}</h1>
              <div className="description text-white">
                {this.state.artist.description}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  render() {
    const settings = {
      dots: true,
      arrows:false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="text-black">
        <Slider {...settings}>{this.sliders()}</Slider>
      </div>
    );
  }
}
