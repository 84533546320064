import React from 'react'
import { useWallet } from "@binance-chain/bsc-use-wallet";
import Banner from './section/Banner'
import Upload from "./section/Upload"
import { adminlist } from "../../config/config";

export default function ArtistLaunchpad() {
    const { account } = useWallet();

    if (adminlist.indexOf(account) === -1) {
        return (
            <div></div>
        )
    }

    return (
        <div className="w-full flex flex-col mx-auto pb-6">
            <Banner />
            <Upload />
        </div>
    )
}
