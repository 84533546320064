import { useEffect } from 'react'
import { getArtists } from '../utils/callHelpers'
import { useNftContract } from './useContract'
import { useDispatch, useSelector } from 'react-redux'
import { pool_store } from '../redux/actions'

export const useArtists = () => {
    const NftContract = useNftContract()
    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            const artists = await getArtists(NftContract);
            dispatch(pool_store({ artistlists: artists }))
        })()
    }, [dispatch, NftContract])

    const { artistlists } = useSelector((store) => store.pool);
    return artistlists;
}