import React from "react";
import * as FaIcons from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

export default function RastaInfo(props) {
  return (
    <div>
      <div className="row flex flex-col gap-4 md:gap-8 mb-6">
        <div className="w-full gap-2 items-detail flex flex-col md:flex-row justify-between border-b-2 pb-2 border-black flex-grow-1">
          {props.farmName ? (
            <h2 className="text-4xl font-bold text-left text-white">{props.farmName}</h2>
          ) : (
            <Skeleton height={40} baseColor="#e2e8f0" />
          )}
          <div className="coin-info flex items-center justify-between">
            <div className="core text-left items-center flex-grow-0 flex flex-row space-x-2 font-bold py-1 px-4 text-white">
              <FaIcons.FaCheckCircle className="fill-current text-white my-auto text-2xl" />
              <span className="text">CORE</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
