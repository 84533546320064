const Contracts = {
    NFT: {
        address: '0x441Af0D47cD02b57a7c39C2f4256427ADdE524AE',
        abi: require("../abis/nft.json")
    },
    multicall: {
        address: '0x7B18483975F54e7f322f35445d483B23Dbae6704',
        abi: require('../abis/multicall.json')
    },
    stake: {
        address: '0x74f63F379A25cb5dD780C525C23Dbb92C1a4C641',
        abi: require("../abis/stake.json")
    },
    mrasta: {
        address: "0xEAA4A2469a8471bD8314b2FF63c1d113FE8114bA",
        abi: require("../abis/mrasta.json")
    }
}

export default Contracts;