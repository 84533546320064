import * as FaIcons from "react-icons/fa";
import * as Io5Icons from "react-icons/io5";
import { Link } from "react-router-dom";

const ArtistInfo = ({ artist, tabs }) => {
    const socialMedia = [
        { name: "Facebook", icon: <FaIcons.FaFacebook size={32} />, link: artist.facebook || '#' },
        { name: "TikTok", icon: <Io5Icons.IoLogoTiktok size={32} />, link: artist.tiktok || '#' },
        { name: "Instagram", icon: <FaIcons.FaInstagram size={32} />, link: artist.instagram || '#' },
        { name: "Twitter", icon: <FaIcons.FaTwitter size={32} />, link: artist.twitter || '#' },
    ];

    return (
        <div className={(tabs === 'artist' ? 'flex ' : 'hidden ') + "social__media items-center space-y-6 mt-4 px-8"}>
            <div className="social__link flex flex-col space-y-6 text-white">
                {socialMedia.map((item, index) => {
                    if (item.link === '#') {
                        return (
                            <Link to={item.link} key={index} className="text-md flex space-x-6 items-center">
                                {item.icon}
                                <p className="mr-5">{item.name}</p>
                            </Link>
                        );
                    } else {
                        return <a href={item.link} rel='noreferrer' target={'_blank'} key={index} className="text-md flex gap-6 items-center">
                            {item.icon}
                            <p>{item.name}</p>
                        </a>
                    }
                })}
            </div>
        </div>
    )
}

export default ArtistInfo