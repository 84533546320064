import React, { useEffect } from "react";
import usePool from "../../hooks/usePool";
import DetailSong from "./DetailSong";
import { useWallet } from "@binance-chain/bsc-use-wallet";

export default function Detail() {
  const { onfetch } = usePool();
  const { account } = useWallet();
  
  useEffect(() => {
      onfetch();
  }, [account, onfetch]);

  return (
      <DetailSong />
  );
}
