import { useWallet } from '@binance-chain/bsc-use-wallet'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import usePool from '../../hooks/usePool'
import Banner from './section/Banner'
import Body from "./section/Body"

export default function ArtistLaunchpad() {
    const { artist_name, type } = useParams()
    const { account } = useWallet()
    const { pool } = useSelector(store => store.pool)
    const { onfetch } = usePool()

    const artist = useMemo(() => {
        return pool?.artistsInfo?.find(e => String(e.name)?.replaceAll(" ", "") === artist_name) || {};
    }, [pool, artist_name])

    const owntokens = useMemo(() => {
        return pool ? pool.nftlists.filter(nft => nft.owner === account) : [];
    }, [pool, account])

    const artisttokens = useMemo(() => {
        return pool ? pool.nftlists.filter(nft => nft.artist === artist?.address) : [];
    }, [pool, artist])

    const isOwnPage = useMemo(() => {
        return account === artist?.address;
    }, [account, artist])

    const [fetched, setFetched] = useState(0);

    useEffect(() => {
        if (fetched === 0 && !account) {
            onfetch()
            setFetched(1)
        }

        if (fetched < 2 && account) {
            onfetch()
            setFetched(2)
        }
    }, [onfetch, fetched, account])

    return (
        <div className="w-full flex flex-col mx-auto pb-6">
            <Banner type={type} artist={artist || {}} />
            <Body nfts={type === 'owner' ? owntokens : artisttokens} artist={artist || {}} isOwnPage={isOwnPage} />
        </div>
    )
}
