import { useCallback } from "react"
import { useDispatch } from "react-redux";
import { artistInfo_store, music_store } from "../redux/actions";
import API from "../utils/api";

export const useBackend = () => {
    const dispatch = useDispatch()

    const onFetchMusic = useCallback(() => {
        API('GET', 'get-music-list', {}).then(({ data }) => {
            if (data) {
                dispatch(music_store(data))
            }
        }).catch(error => {
            console.error(error);
        })
    }, [dispatch])

    const onFetchArtist = useCallback(() => {
        API('GET', 'get-artist-list', {}).then(({ data }) => {
            if (data) {
                dispatch(artistInfo_store(data))
            }
        }).catch(error => {
            console.error(error);
        })
    }, [dispatch])

    return { onFetchArtist, onFetchMusic }
}