import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import BigNumber from "bignumber.js";
import PulseLoader from "react-spinners/PulseLoader";
import { useClaimable } from "../../../../../../hooks/useReward";
import { customToFixed } from "../../../../../../hooks/hook";
import Wallet from "./Wallet";
import { locktime } from "../../../../../../config/config";
import { Link } from "react-router-dom";
import Bignumber from "bignumber.js"
import { useWallet } from '@binance-chain/bsc-use-wallet'

export default function FarmHarvest({ 
  nft, 
  userInfo, 
  onFetch,
  totalValue,
  stakedValue, }) {
  const [loading, setLoading] = useState(false);
  const { onClaim } = useClaimable();
  const { account } = useWallet()

  const claim = async () => {
    if (Number(userInfo.pendingRewards) > 0) {
      setLoading(true);
      try {
        await onClaim();
        await onFetch();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="row flex flex-col md:flex-row gap-4 md:gap-12 w-full md:pb-12 mb-6">
        <div className="w-full items-detail flex flex-col">
          <span className="text-white text-left text-xs">
            MRASTA EARNED
          </span>
          <h2 className="text-xl font-bold text-left text-white">
            {customToFixed(
              new BigNumber(userInfo.pendingRewards)
                .div(new BigNumber(10).pow(18))
                .times(60)
                .div(100)
                .toString(),
              3
            )}
          </h2>
          <span className="text-white text-xs text-left">
            30 days harvest lock
          </span>
          <Link
            to={`/detail/${nft.id}`}
            className="py-2 mt-3 flex-row space-x-2 flex w-full items-center justify-center border-1 text-white border-white shadow-outerWhite"
          >
            <FaIcons.FaShoppingBag />
            <span className="mt-1">BUY MORE</span>
          </Link>
          <div className="w-full md:w-3/4 harvest flex flex-col mt-4 space-y-4">
            <Wallet nft={nft} userInfo={userInfo} onFetch={onFetch} />
            <button
              onClick={claim}
              className={
                (Number(userInfo.pendingRewards) > 0 &&
                new Date().getTime() / 1000 - locktime >=
                  Number(userInfo.depositedAt)
                  ? ""
                  : "disabled:cursor-not-allowed disabled:opacity-50") +
                " py-2 flex-row space-x-2 flex w-full items-center justify-center border-1 text-white border-white shadow-outerWhite"
              }
              disabled={
                Number(userInfo.pendingRewards) > 0 &&
                new Date().getTime() / 1000 - locktime >=
                  Number(userInfo.depositedAt)
                  ? false
                  : true
              }
            >
              {loading ? (
                <PulseLoader size={8} color="white" />
              ) : (
                <>
                  <FaIcons.FaCoins />
                  <span className="text-sm font-bold text-white">HARVEST</span>
                </>
              )}
            </button>
          </div>
          <div className="detail w-full flex flex-col text-white space-y-2 mt-6">
          <span className="font-bold">Detail:</span>
          <div className="stake flex flex-row justify-between">
            <span className="title">Stake</span>
            <span className="content underline italic">NFT</span>
          </div>
          <div className="flex justify-between w-full">
            <span className="">Total Pool Liquidity :</span>
            <div className="flex  flex-col space-x-4">
              {new Bignumber(totalValue)
                .div(new Bignumber(10).pow(18))
                .toString() || 0}{" "}
              MRASTA
            </div>
          </div>
          {account && (
            <div className="flex justify-between w-full">
              <span className="">My Staked Liquidity :</span>
              <div className="flex  flex-col space-x-4">
                {new Bignumber(stakedValue)
                  .div(new Bignumber(10).pow(18))
                  .toString() || 0}{" "}
                MRASTA
              </div>
            </div>
          )}
        </div>
        </div>
      </div>
    </div>
  );
}
