import React from "react";
import { FaArrowRight } from "react-icons/all";
import { Link } from "react-router-dom";
import SlickSlide from "./SlickSlide/CurrentLaunchpadSlick"

export default function CurrentLaunchpad() {
  return (
    <div className="current-launchpad flex flex-col space-y-8">
      <div className="header flex flex-col md:flex-row items-left px-5">
        <div className="title-bar flex-grow-1">
          <h2 className="text-lg md:text-2xl font-bold">Raggadat Cris</h2>
        </div>
        <Link className="browse flex-row hidden md:flex items-center space-x-4" to={"/launchpad-artists"}>
          <span>Browse More</span>
          <span>
            <FaArrowRight />
          </span>
        </Link>
      </div>
      <div className="body flex flex-col gap-4">
        <SlickSlide type="all" />
      </div>
    </div>
  );
}
