export const PER_SEC_YEAR = 31536000;
export const adminlist = ['0x100DAF80419D16262B93Db9915BE959164D421B3', '0xd765a47681716C96f4e9C33A5A4294b9026A6A33'];
export const locktime = 2592000;
export const API_KEY = 'caa6d26f7d8de69f2a44';
export const API_SECRET = 'dfb9f1a005a5f3080800c87b6f3092ce662431c15612052ff94530da812ae542';

// export const Raggadat_NFT = [
//     'https://zionlabs.mypinata.cloud/ipfs/QmVBZM4YEqUBCuC6YXdyAYveMNLbm7bL3jEr59RFpWUxsv',
//     'https://zionlabs.mypinata.cloud/ipfs/QmarbuHNLRU22rRUKiCGkZvjHQuN8MS9UqszPBCdfVRpst',
//     'https://zionlabs.mypinata.cloud/ipfs/QmPbp2pLv5MpHfVnmKGzNWqCFVmAnMiNfBFr4cB4mgCGRX',
// ]

export const Raggadat_NFT = [
    'https://zionlabs.mypinata.cloud/ipfs/QmQ6GEmuRar9sqdPJCKYdy28oDyAyTwtsPnpyXjoa8pqrg',
    'https://zionlabs.mypinata.cloud/ipfs/QmYvuMTtT7d8BGor3kG8YCWN8QruVKrKRUSgntBK8cxtoA',
    'https://zionlabs.mypinata.cloud/ipfs/QmYFeXCfM3SJN9KTKxRYtfeSzsLk66vtmZXRC6USFi3EyL',
]