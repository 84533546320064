import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import { useMobileStatus } from "../../../../../hooks/hook";
import { SERVER_URL } from "../../../../../utils/api";

const MultipleSlick = ({ nfts, artist, isOwnPage }) => {
  const isMobile = useMobileStatus()

  return (
    <div className="text-black flex flex-col items-center md:block">
      {isMobile ? (
        <Swiper
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={1.7}
          effect={"coverflow"}
          modules={[EffectCoverflow]}
          coverflowEffect={{
            rotate: 0,
            stretch: -12,
            depth: 100,
            modifier: 5,
            slideShadows: true
          }}
          className="w-11/12"
        >
          {
            nfts && (
              nfts.map((item, index) => {
                return <SwiperSlide key={index} style={{ padding: 5 }}>
                  <Link
                    to={
                      artist.name ?
                        isOwnPage ?
                          (`/${String(artist.name)?.replaceAll(" ", "")}/${item.name}_${item.id}/stake`)
                          :
                          (`/${String(artist.name)?.replaceAll(" ", "")}/${item.name}_${item.id}`)
                        : "#"}
                    className="card flex flex-col hover:shadow-box bg-white"
                  >
                    <div className="thumbnail" style={{ height: 190 }}>
                      {
                        item.description ?
                          <LazyLoadImage src={`${SERVER_URL}videos/raggadat-cris-${item.id}.gif`} alt={item.name} className="object-cover w-full h-full" />
                          :
                          <ClipLoader color="#0006" />
                      }
                    </div>
                    <div className="song-detail my-4 px-4 flex flex-col space-y-2">
                      {
                        item.description ?
                          <div className="artist-name text-xl font-bold text-center md:text-left">
                            {item.name}
                          </div>
                          :
                          <Skeleton height={30} baseColor="#E7E9EB" highlightColor="white" />
                      }
                      {
                        item.description ?
                          // <div className="artist-name text-md" style={{ color: "red" }}>
                          <div className="artist-name text-sm text-blue-zion">
                            {item.price} MRASTA
                          </div>
                          :
                          <Skeleton height={30} baseColor="#E7E9EB" highlightColor="white" />
                      }
                    </div>
                  </Link>
                </SwiperSlide>
              })
            )
          }
        </Swiper>
      ) : (
        nfts && (
          nfts.map((item, index) => (
            <Link
              to={
                artist.name ?
                  isOwnPage ?
                    (`/${String(artist.name)?.replaceAll(" ", "")}/${item.name}_${item.id}/stake`)
                    :
                    (`/${String(artist.name)?.replaceAll(" ", "")}/${item.name}_${item.id}`)
                  : "#"}
              className="cardv inline-block current__launchpad__item gap-2 hover:shadow-box"
              key={index}
              style={{
                width: !isMobile ? "23%" : '220px',
                margin: "1%"
              }}
            >
              <div className="thumbnail more-artists-img" style={{ height: '250px' }}>
                {
                  item.description ?
                    (item.type === 'video' && !item.preview ?
                      <video
                        // controls
                        className="object-cover w-full h-full"
                      >
                        <source src={item.url} type='video/mp4' />
                      </video>
                      :
                      <LazyLoadImage src={item.preview || item.url} alt={item.name} className="object-cover w-full h-full" />
                    )
                    :
                    <ClipLoader color="#0006" />
                }
              </div>
              <div className="song-detail my-4 px-4 flex flex-col space-y-2">
                {
                  item.description ?
                    <div className="artist-name text-xl font-bold text-center md:text-left">
                      {item.name}
                    </div>
                    :
                    <Skeleton height={30} baseColor="#E7E9EB" highlightColor="white" />
                }
                {
                  item.description ?
                    // <div className="artist-name text-md" style={{ color: "red" }}>
                    <div className="artist-name text-sm text-blue-zion">
                      {item.price} MRASTA
                    </div>
                    :
                    <Skeleton height={30} baseColor="#E7E9EB" highlightColor="white" />
                }
              </div>
            </Link>
          ))
        ))
      }
    </div>
  );
}
export default MultipleSlick;