import React from "react";
import * as RiIcons from "react-icons/ri";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as Io5Icons from "react-icons/io5";
import styled from 'styled-components';
import Thumbnail from "./../../../assets/purple.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SERVER_URL } from "../../../utils/api";

const Card = styled.div`
  @media (max-width: 600px){
    width : 100% !important
  }
`
const SongTrack = ({ track, changeHandler, playing, toggle, artist, isMobile, tabs }) => {

  const socialMedia = [
    { name: "Facebook", icon: <FaIcons.FaFacebook />, link: artist.facebook || '#' },
    { name: "Tik Tok", icon: <Io5Icons.IoLogoTiktok />, link: artist.tiktok || '#' },
    { name: "Instagram", icon: <FaIcons.FaInstagram />, link: artist.instagram || '#' },
    { name: "Twitter", icon: <FaIcons.FaTwitter />, link: artist.twitter || '#' },
  ];

  const Change = (index) => {
    // props.playing = false;
    changeHandler(index);
    if (playing)
      toggle();
    // const [playing, toggle] = props.useAudio(songFiles);
    // return props.useAudio(songFiles);
  };

  return (
    <Card
      className={(tabs === 'song' ? 'flex ' : 'hidden md:flex ') + "border-1 w-7/12 border-white shadow-outerWhite px-5 py-8 md:p-8 pt-0 songtrack flex-col items-center space-y-4 mt-8 md:mt-0 justify-between"}
      
    >
      <div className="w-full p-4 md:p-0">
        <div className="title flex flex-row justify-between text-white w-full text-sm md:text-md pb-2 border-gray-300">
          <div className="flex flex-row space-x-2">
            
            <span className="italic">Related Tracks</span>
          </div>
        </div>
        <div
          className="track-list flex flex-col w-full text-gray-600 py-4  space-y-5"
          style={{ overflow: 'auto' }}
        >
          {
            track.map((item, index) => (
              <button
                className="track-item md:space-x-4 items-center hover:bg-gray-200 hover:bg-opacity-50 flex justify-between p-3 pr-5 border-1 border-white shadow-outerWhite"
                key={index}
                onClick={() =>
                  Change(index)
                }
              >
                <div className="flex flex-row space-x-5">
                  <div className="thumbnail flex-grow-1 w-20 h-20">
                    {
                      artist.avatar_url ?
                        <LazyLoadImage
                          src={`${SERVER_URL}profile/${artist.avatar_url}`}
                          className="w-12 md:w-20 rounded-sm h-full object-cover"
                          alt={'user'}
                        />
                        :
                        <LazyLoadImage
                          src={Thumbnail}
                          className="w-12 md:w-20 rounded-sm h-full object-cover"
                          alt={'user'}
                        />
                    }
                  </div>
                  <div className="song flex-grow-1 flex-col text-left md:w-auto flex justify-center text-sm md:text-md">
                    <div className="user capitalize text-white">{artist.name || 'Artist'}</div>
                    <span className="title capitalize text-white font-bold text-md">
                      {item.title || "New Upload"}
                    </span>
                    {/* <div className="likes flex flex-row text-sm space-x-2 items-center mt-1">
                      <div className="icon">
                        <FaIcons.FaHeart className="fill-current text-red-rasta" />
                      </div>
                      <div className="totalLikes">{item.size ? Number(item.size / 1024 / 1024).toFixed(1) : 0}M</div>
                    </div> */}
                  </div>
                </div>
              </button>
            ))
          }
        </div>
      </div>
      <div className="social__media hidden md:flex flex-col space-y-6 mt-4 border-1 border-white shadow-outerWhite text-white px-4 py-4 w-full">
        <div className="title">Social Media</div>
        <div className="social__link flex space-x-2">
          {socialMedia.map((item, index) => {
            if (item.link === '#') {
              return (
                <Link to={item.link} key={index} className="text-2xl text-white">
                  {item.icon}
                </Link>
              );
            } else {
              return <a href={item.link} rel='noreferrer' target={'_blank'} key={index} className="text-2xl text-white">
                {item.icon}
              </a>
            }
          })}
        </div>
      </div>
    </Card>
  );
};

export default SongTrack;
