import React, { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import profileHeader from "./../../../assets/profile-header.jpg";
import profileHeader from "./../../../assets/profile-header-blue.jpg";
import DefaultProfile from "./../../../assets/purple.jpg";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Player from "./Player";
import * as FaIcons from "react-icons/fa";
import API, { SERVER_URL } from "../../../utils/api";

export default function Header(props) {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [title, setTitle] = useState('')
  const [released, setReleased] = useState('')
  const [description, setDescription] = useState('')
  const [name, setName] = useState('')
  const [facebook, setFacebook] = useState('https://www.facebook.com/')
  const [twitter, setTwitter] = useState('https://twitter.com/')
  const [instagram, setInstagram] = useState('https://www.instagram.com/')
  const [tiktok, setTiktok] = useState('https://www.tiktok.com/')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTitle(props.intro.title)
    setReleased(props.intro.released)
    setDescription(props.artist.description)
    setName(props.artist.name)
    setFacebook(props.artist.facebook || 'https://www.facebook.com/')
    setTwitter(props.artist.twitter || 'https://twitter.com/')
    setInstagram(props.artist.instagram || 'https://www.instagram.com/')
    setTiktok(props.artist.tiktok || 'https://www.tiktok.com/')
    setImage({
      preview: props.artist.avatar_url ? `${SERVER_URL}profile/${props.artist.avatar_url}` : '',
      raw: ''
    })
  }, [props])

  const _handleSave = async () => {
    if (!loading) {
      setLoading(true)
      const Form = new FormData();
      if (image.raw) {
        Form.append('avatar', image.raw);
      }
      Form.append('title', title);
      Form.append('address', props.address);
      Form.append('id', props.intro.id);
      Form.append('released', released);
      Form.append('name', name);
      Form.append('description', description);
      Form.append('facebook', facebook);
      Form.append('twitter', twitter);
      Form.append('instagram', instagram);
      Form.append('tiktok', tiktok);

      try {
        await API('POST', 'edit-profile', Form);
        setImage(prev => ({
          ...prev, raw: ''
        }))
        props.onfetch()
        setShowModal(false)
        setLoading(false);
      } catch (error) {
        console.log(error);
        NotificationManager.error('Network error!', '', 3000);
        setLoading(false);
      }
    }
  };


  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleUpload = async () => {
    const file_input = document.querySelector('#upload-button');
    file_input.click()
  };

  return (
    <div
      className="flex w-full py-16 flex-col bg-cover bg-blend-overlay bg-opacity-25  bg-top text-white  items-center"
      style={{
        backgroundImage: "url(" + profileHeader + ")",
        backgroundSize: "cover",
        backgroundPosition: "bottom center",
      }}
    >
      <div className="mx-auto flex flex-col md:flex-row w-full px-8 md:px-0 md:w-7/12 items-start gap-6 md:gap-12">
        <div className="profile w-full md:w-2/5">
          {
            image.preview ?
              <LazyLoadImage
                src={image.preview}
                alt='Artist Profile'
                className={`w-full mx-auto rounded-2xl object-cover`}
                effect="blur"
              />
              :
              <LazyLoadImage
                src={DefaultProfile}
                alt='Artist Profile'
                className={`w-full mx-auto rounded-2xl object-cover`}
                effect="blur"
              />
          }
        </div>
        <div className="music-play flex flex-col space-y-2 flex-grow-1 md:px-8 w-full md:w-4/5 items-center md:items-start">
          <Player playing={props.playing} toggle={props.toggle} handleNext={props.handleNext} handlePrevious={props.handlePrevious} />
          <div className="title flex flex-grow-1 w-full justify-center md:justify-start">
            <h1 className="text-xl text-center md:text-left md:text-4xl font-bold text-yellow-rasta capitalize">
              {name || 'Unknown'} - {props.intro.title || 'New Upload'}
            </h1>
          </div>
          <div className="intro-detail hidden md:flex flex-col w-4/12">
            <div className="length grid grid-cols-3">
              <span className="col-span-2 text-white flex-grow-1 uppercase">
                Length
              </span>
              <span className="capitalize">{props.intro.duration || '00:00'}</span>
            </div>
          </div>
          {/* <div className="intro-detail hidden md:flex flex-col w-4/12">
            <div className="length grid grid-cols-3">
              <span className="col-span-2 text-white flex-grow-1 uppercase">
                Genre
              </span>
              <span className="capitalize">{props.intro.genre || 'Unknown'}</span>
            </div>
          </div>
          <div className="intro-detail hidden md:flex flex-col w-4/12">
            <div className="length  grid grid-cols-3">
              <span className="col-span-2 text-white flex-grow-1 uppercase">
                Fund Raised
              </span>
              <span className="capitalize">{props.intro.raised || 'Unknown'}</span>
            </div>
          </div> */}
          <div className="intro-detail hidden md:flex flex-col w-4/12">
            <div className="length  grid grid-cols-3">
              <span className="col-span-2 text-white flex-grow-1 uppercase">
                Release
              </span>
              <span className="capitalize">{props.intro.released || 'Unknown'}</span>
            </div>
          </div>
          <div className="description w-full pt-4">
            <p className="text-center text-sm md:text-md md:text-left">{props.artist.description || ''}</p>
          </div>
          <div className="edit-info-btn text-right w-full flex flex-col items-center md:items-end pt-8">
            <button
              className="bg-white text-black flex flex-row px-6 py-2  rounded-xl space-x-2 items-center text-xs md:text-md"
              onClick={() => setShowModal(true)}
            >
              <span className="icon">
                <FaIcons.FaEdit size={20} />
              </span>
              <span className="text font-bold">EDIT SONG INFO</span>
            </button>
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full md:w-1/3 my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-rasta pb-8 outline-none focus:outline-none mt-56">
                {/*header*/}
                <div className="flex justify-center p-5 border-b-1 border-solid border-gray-100 rounded-t mt-10">
                  <h3 className="text-2xl md:text-3xl font-semibold">Edit Profile</h3>
                  <button
                    className="p-1 bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute"
                    onClick={() => setShowModal(false)}
                    style={{ right: "25px" }}
                  >
                    <span className="bg-transparent text-white opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex flex-col space-y-6">
                  <div className="edit__profile flex flex-col items-center w-full space-y-4">
                    <div className="top__bar flex justify-between w-full">
                      <span className="font-bold text-md text-white">
                        Edit Profile
                      </span>
                      {/* <button className="text-orange-rasta">Edit</button> */}
                    </div>
                    <div className="image__preview w-full items-center flex justify-center">
                      <label htmlFor="upload-button mx-auto cursor-pointer">
                        {image.preview ? (
                          <img
                            src={image.preview}
                            alt="dummy"
                            width="150"
                            height="150"
                            className="object-cover"
                          />
                        ) : (
                          <>
                            <img
                              src={DefaultProfile}
                              alt="dummy"
                              width="150"
                              height="150"
                              className="object-cover"
                            />
                          </>
                        )}

                        <button
                          onClick={handleUpload}
                          className=" relative bottom-8 bg-gray-rasta rounded-full p-2 left-24 ml-6 shadow-xl"
                          style={{ boxShadow: '1px 2px 5px black' }}
                        >
                          <FaIcons.FaCamera size={20} />
                        </button>
                        <input
                          type="file"
                          id="upload-button"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="customize__intro flex flex-col w-full space-y-4">
                    <div className="top__bar flex justify-between w-full">
                      <span className="font-bold text-md text-white">
                        Customize Song Description
                      </span>
                      {/* <button className="text-orange-rasta">Edit</button> */}
                    </div>
                    <div className="detail__intro grid grid-cols-1 w-full gap-1">
                      <div className="title flex flex-row flex-grow-1 space-x-2 items-center">
                        <span className="font-bold text-white  uppercase label tracking-wide w-5/12">
                          Title
                        </span>
                        <input
                          value={title}
                          onChange={e => setTitle(e.target.value)}
                          className="flex-grow-1 song__title bg-transparent px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-0" placeholder="title..." />
                      </div>
                      <div className="fund flex flex-row flex-grow-1 space-x-2 items-center">
                        <span className="font-bold text-white  uppercase label tracking-wide w-5/12">
                          Release
                        </span>
                        <input
                          value={released}
                          onChange={e => setReleased(e.target.value)}
                          className="flex-grow-1 song__title bg-transparent px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-0" placeholder="release..." />
                      </div>
                    </div>
                  </div>
                  <div className="customize__intro flex flex-col w-full space-y-4">
                    <div className="top__bar flex justify-between w-full">
                      <span className="font-bold text-md text-white">
                        Customize Your Intro
                      </span>
                      {/* <button className="text-orange-rasta">Edit</button> */}
                    </div>
                    <div className="title flex flex-row flex-grow-1 space-x-2 items-center">
                      <span className="font-bold text-white  uppercase label tracking-wide w-5/12">
                        Name
                      </span>
                      <input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className="flex-grow-1 song__title bg-transparent px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-0" placeholder="name..." />
                    </div>
                    <div className="detail__intro grid grid-cols-1 w-full">
                      <textarea
                        value={description}
                        placeholder="description..."
                        onChange={e => setDescription(e.target.value)}
                        className="bg-transparent text-gray-500 p-2" rows={6} style={{ resize: "none" }} />
                    </div>
                  </div>
                  <div className="social__media flex flex-col w-full space-y-4">
                    <div className="top__bar flex justify-between w-full">
                      <span className="font-bold text-md text-white">
                        Social Media
                      </span>
                    </div>
                    <div className="detail__intro grid grid-cols-1 w-full gap-1">
                      <div className="title flex flex-row flex-grow-1 space-x-2 items-center">
                        <span className="font-bold text-gray-500  capitalize label tracking-wide w-5/12">
                          Facebook
                        </span>
                        <input
                          value={facebook}
                          onChange={e => setFacebook(e.target.value)}
                          className="flex-grow-1 song__title bg-transparent px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md text-sm md:text-md focus:ring-0" placeholder="https://www.facebook.com/" />
                      </div>
                      <div className="title flex flex-row flex-grow-1 space-x-2 items-center">
                        <span className="font-bold text-gray-500  capitalize label tracking-wide w-5/12">
                          Twitter
                        </span>
                        <input
                          value={twitter}
                          onChange={e => setTwitter(e.target.value)}
                          className="flex-grow-1 song__title bg-transparent px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md text-sm md:text-md focus:ring-0" placeholder="https://twitter.com/" />
                      </div>
                      <div className="title flex flex-row flex-grow-1 space-x-2 items-center">
                        <span className="font-bold text-gray-500   capitalize label tracking-wide w-5/12">
                          Instagram
                        </span>
                        <input
                          value={instagram}
                          onChange={e => setInstagram(e.target.value)}
                          className="flex-grow-1 song__title bg-transparent px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md text-sm md:text-md focus:ring-0" placeholder="https://www.instagram.com/" />
                      </div>
                      <div className="title flex flex-row flex-grow-1 space-x-2 items-center">
                        <span className="font-bold text-gray-500   capitalize label tracking-wide w-5/12">
                          Tiktok
                        </span>
                        <input
                          value={tiktok}
                          onChange={e => setTiktok(e.target.value)}
                          className="flex-grow-1 song__title bg-transparent px-3 py-2 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md text-sm md:text-md focus:ring-0" placeholder="https://www.tiktok.com/" />
                      </div>
                    </div>
                    <div className="button w-full flex items-center">
                      <button
                        onClick={_handleSave}
                        className="w-full bg-gradient-to-r from-yellow-rasta to-green-rasta rounded-md py-2">
                        {
                          loading ?
                            <PulseLoader size={10} color="white" />
                            :
                            <span>DONE</span>
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null
      }
      <NotificationContainer />
    </div >
  );
}
