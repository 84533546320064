import React from 'react'
import { SERVER_URL } from '../../../../utils/api';

export default function AboutTab({ artist }) {

    return (
        <div className='flex flex-row gap-5'>
            {
                artist.avatar_url && (
                    <img className='rounded-full hidden md:block' alt='artist profile' style={{ width: 100, height: 100, objectFit: 'cover' }} src={`${SERVER_URL}profile/${artist.avatar_url}`} />
                )
            }
            <div className='flex gap-2 flex-col'>
                {
                    artist.name && (
                        <p className='font-bold text-lg'>{artist.name}</p>
                    )
                }
                <p>{artist.description}</p>
            </div>
        </div>
    )
}
