import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import BigNumber from "bignumber.js";
import PulseLoader from "react-spinners/PulseLoader";
import { useClaimable } from "../../../../../../hooks/useReward";
import { customToFixed } from "../../../../../../hooks/hook";
import { locktime } from "../../../../../../config/config";
import { Link } from "react-router-dom";
import Bignumber from "bignumber.js"
import { useWallet } from '@binance-chain/bsc-use-wallet'

export default function FarmHarvest({
  nft,
  userInfo,
  onFetch,
  farmApr,
  totalValue,
  stakedValue,
}) {
  const [loading, setLoading] = useState(false);
  const { onClaim } = useClaimable();
  const { account } = useWallet()

  const claim = async () => {
    if (Number(userInfo.pendingRewards) > 0) {
      setLoading(true);
      try {
        await onClaim();
        await onFetch();
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="row flex flex-col gap-4 md:gap-8 w-full border-b-2 border-black pb-6 md:pb-8 mb-6">
        <div className="flex-row flex">
          <div className="apr w-full py-3 flex flex-col justify-between  ">
            <span className="apr-label text-white text-md ">APR</span>
            <span className="apr-value text-3xl font-bold w-full text-white ">
              {Number(farmApr) ? `${Number(farmApr).toFixed()}%` : "-"}
            </span>
            <div>
              <button
                onClick={claim}
                className={
                  (Number(userInfo.pendingRewards) > 0 &&
                  new Date().getTime() / 1000 - locktime >=
                    Number(userInfo.depositedAt)
                    ? ""
                    : "disabled:cursor-not-allowed disabled:opacity-50") +
                  " py-2 flex-row space-x-2 flex px-4 items-center justify-center bg-gradient-to-b text-black bg-white"
                }
                disabled={
                  Number(userInfo.pendingRewards) > 0 &&
                  new Date().getTime() / 1000 - locktime >=
                    Number(userInfo.depositedAt)
                    ? false
                    : true
                }
              >
                {loading ? (
                  <PulseLoader size={10} color="white" />
                ) : (
                  <>
                    <span>Harvest</span>
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="w-full items-detail flex flex-col py-3 ml-0 justify-between items-end">
            <span className="text-white text-left">MRASTA EARNED</span>
            <h2 className="text-3xl font-bold text-left text-white">
              {customToFixed(
                new BigNumber(userInfo.pendingRewards)
                  .div(new BigNumber(10).pow(18))
                  .times(60)
                  .div(100)
                  .toString(),
                3
              )}
            </h2>
            <span className="text-white text-left italic underline text-sm">
              30 days harvest lock
            </span>
            <div className=" harvest flex mt-4 md:mt-12 gap-4">
              <Link
                to={`/detail/${nft.id}`}
                className=" py-2 flex-row space-x-2 flex px-4 items-center justify-center text-white border-1 border-white"
              >
                <span>Buy more</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="detail w-full flex flex-col text-white space-y-2">
          <span className="font-bold">Detail:</span>
          <div className="stake flex flex-row justify-between">
            <span className="title">Stake</span>
            <span className="content underline italic">NFT</span>
          </div>
          <div className="flex justify-between w-full">
            <span className="">Total Pool Liquidity :</span>
            <div className="flex  flex-col space-x-4">
              {new Bignumber(totalValue)
                .div(new Bignumber(10).pow(18))
                .toString() || 0}{" "}
              MRASTA
            </div>
          </div>
          {account && (
            <div className="flex justify-between w-full">
              <span className="">My Staked Liquidity :</span>
              <div className="flex  flex-col space-x-4">
                {new Bignumber(stakedValue)
                  .div(new Bignumber(10).pow(18))
                  .toString() || 0}{" "}
                MRASTA
              </div>
            </div>
          )}
        </div>
        <div className="w-full px-6 py-1 flex flex-row items-center  border-1 border-white justify-between">
          <span className="apr-label text-white text-md flex-grow-1 w-1/4">
            Deposit Fee
          </span>
          <span className="apr-value text-2xl w-full text-white text-right">
            {0}%
          </span>
        </div>
      </div>
    </div>
  );
}
