import React from "react";
import SlickSlide from "./elements/slickSlider/SlickSlide"

export default function Body({ nfts, artist, isOwnPage }) {

  return (
    <div className="bg-white py-16">
      <div className="wrapper max-w-screen-lg mx-auto">
        <div className="artist-launchpad flex flex-col space-y-8">
          <div className="header flex flex-col md:flex-row items-center">
            <div className="title-bar flex-grow-1 flex flex-col items-center text-center">
              <h2 className="text-xl md:text-2xl font-bold">
                {
                  isOwnPage ?
                    "Your Purchased NFTs"
                    :
                    "Discover the Latest Releases"
                }
              </h2>
              <p className="p-5 md:w-3/4">
                {
                  isOwnPage ?
                    "Here is where you can find all of your ZLP NFTs"
                    :
                    "Explore, listen and engage with what's trending on the Zion Launchpad. Support your favourite artists and receive exclusive audio, NFTs, and VIP streams from around the world!"
                }
              </p>
            </div>
          </div>
          <div className="body grid grid-cols-1 md:grid-cols-1 gap-3">
            <SlickSlide nfts={nfts} artist={artist} isOwnPage={isOwnPage} />
          </div>
        </div>
      </div>
    </div>
  );
}
