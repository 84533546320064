import React, { useState } from "react";
import * as RiIcons from "react-icons/ri";
import styled from 'styled-components';
import Thumbnail from "./../../../assets/purple.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import API, { SERVER_URL } from "../../../utils/api";
import { useMobileStatus } from "../../../hooks/hook";
import * as FaIcons from "react-icons/fa";

const Card = styled.div`
  @media (max-width: 600px){
    width : 100% !important
  }
`
const SongTrack = ({ track, changeHandler, playing, toggle, artist, tabs, onfetch }) => {
  const _mobile = useMobileStatus()
  const [selected, setSelected] = useState()
  const [loading, setLoading] = useState(false)

  const Change = (index, id) => {
    // props.playing = false;
    setSelected(id)
    changeHandler(index);
    if (playing)
      toggle();
    // const [playing, toggle] = props.useAudio(songFiles);
    // return props.useAudio(songFiles);
  };

  const _handleRemove = async (id) => {
    if (!loading) {
      setLoading(true)
      await API('GET', `remove-music/${id}`);
      await onfetch()
      setLoading(false)
    }
  }

  return (
    <Card
      className={(tabs === 'songtrack' ? 'flex ' : 'hidden md:flex ') + "md:shadow-lg pb-10 px-5 md:px-6 pt-0 rounded-3xl songtrack flex-col items-center space-y-4 mt-8 md:mt-0 justify-between"}
      style={{ width: '450px' }}
    >
      <div className="w-full">
        <div className="pl-3 title flex flex-row text-gray-600  space-x-2 w-full text-sm md:text-md">
          <span className="icon">
            <RiIcons.RiPlayListFill size={_mobile ? 18 : 24} />
          </span>
          <span>Related Tracks</span>
        </div>
        <div
          className="track-list flex flex-col w-full text-gray-600 pt-4"
          style={{ height: '350px', overflow: 'auto' }}
        >
          {
            track.map((item, index) => (
              <button
                className={(selected === item.id ? 'bg-gray-400 bg-opacity-50 ' : 'hover:bg-gray-300 hover:bg-opacity-50 ') + "track-item md:space-x-4 items-center flex justify-between p-3 rounded-md pr-5"}
                key={index}
                onClick={() =>
                  Change(index, item.id)
                }
              >
                <div className="flex flex-row space-x-5">
                  <div className="thumbnail flex-grow-1 w-16 h-16">
                    {
                      artist.avatar_url ?
                        <LazyLoadImage
                          src={`${SERVER_URL}profile/${artist.avatar_url}`}
                          className="w-24 md:w-16 h-full object-cover"
                          alt={'user'}
                        />
                        :
                        <LazyLoadImage
                          src={Thumbnail}
                          className="w-24 md:w-16 h-full object-cover"
                          alt={'user'}
                        />
                    }
                  </div>
                  <div className="song flex-grow-1 flex-col text-left md:w-auto flex justify-center gap-2 text-sm md:text-md">
                    <div className="user capitalize">{artist.name || 'Artist'}</div>
                    <span className="title capitalize text-black text-md">
                      {item.title || "New Upload"}
                    </span>
                  </div>
                </div>
                {
                  selected === item.id ?
                    <div className={(loading ? 'cursor-not-allowed ' : '') + "flex text-lg"}>
                      <FaIcons.FaTrash color="red" onClick={() => _handleRemove(item.id)} />
                    </div>
                    :
                    <div className="duration pl-8 hidden md:block">{item.duration}</div>
                }
              </button>
            ))
          }
        </div>
      </div>
    </Card>
  );
};

export default SongTrack;
