import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allowance, approve, buyNFT } from '../utils/callHelpers'
import { useNftContract, useMrastaContract } from './useContract'

const useBuyNFT = () => {
    const { account } = useWallet()
    const mrastaContract = useMrastaContract()
    const nftContract = useNftContract()

    const _handleBuy = useCallback(async (tokenId, price, supply) => {
        if (account) {
            try {
                const allowed = await allowance(mrastaContract, account);
                if (Number(new BigNumber(allowed).toString()) < Number(new BigNumber(price).times(new BigNumber(10).pow(18)).toString())) {
                    await approve(mrastaContract, account)
                }
                const txHash = await buyNFT(nftContract, tokenId, supply, account)
                return txHash;
            } catch (error) {
                console.log(error);
                return {};
            }
        } else {
            return {};
        }
    }, [account, mrastaContract, nftContract])

    return { onBuy: _handleBuy }
}

export default useBuyNFT