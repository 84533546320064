import { useCallback } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { claim, claimable } from '../utils/callHelpers'
import { useStakeContract } from './useContract'

export const useClaimable = () => {
    const { account } = useWallet()
    const poolContract = useStakeContract()

    const _handleClaimable = useCallback(
        async (tokenId) => {
            return await claimable(poolContract, tokenId);
        },
        [poolContract],
    )

    const _handleClaim = useCallback(
        async () => {
            if (account) {
                return await claim(poolContract, account);
            } else {
                return {};
            }
        },
        [account, poolContract],
    )

    return { onClaimable: _handleClaimable, onClaim: _handleClaim }
}