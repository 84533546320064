import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import usePool from "../../hooks/usePool";
import FirstSection from "../../assets/newlaunchpadpage/marcela-laskoski-YrtFlrLo2DQ-unsplash-1.jpg";
// import SecondSection from "../../assets/newlaunchpadpage/leo-wieling-Sby_GQz1-5E-unsplash-1.jpg";
import SecondSection from "../../assets/newimage/shout-out.jpg"
import ThirdSection from "../../assets/newlaunchpadpage/feliphe-schiarolli-oeHaXN3WiLk-unsplash-1.jpg";
import SectionImage from "../../assets/newlaunchpadpage/section-image.png";
// import SlickSlide from "./section/elements/slickSlider/SlickSlide";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function ArtistLaunchpad() {
  const { pool } = useSelector((store) => store.pool);
  const { onfetch } = usePool();
  const location = useLocation()

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched) {
      onfetch();
      setFetched(true);
    }
  }, [onfetch, fetched]);

  return (
    <div className="text-white">
      <div
        className="h-screen"
        style={{
          backgroundImage: `url(${FirstSection})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="h-full w-full">
          <div className="h-full w-full flex items-center justify-center flex-col">
            <span>Welcome to the</span>
            <h1 className="text-4xl md:text-6xl font-bold text-heading uppercase">
              Zion Launch Pad
            </h1>
          </div>
        </div>
      </div>
      {/* <div className="lg:h-screen bg-black"> */}
      <div className="bg-black lg:py-40">
        <div className="h-full w-full grid md:grid-cols-2 items-center">
          <div className="flex flex-col items-center md:items-start justify-center px-5 text-center md:text-left md:px-20 gap-y-4">
            <div className="flex flex-col gap-y-4">
                
                <div className='breadcrumb text-gray-500'>
                  <Link to='/'>Artist NFTs</Link>
                   &nbsp; &gt; &nbsp;
                   <Link to={location.pathname} className={location.pathname && 'underline'}>Launchpad Artists</Link>
                </div>

              <h2 className="text-4xl text-heading font-bold">
                What is a ZION Launch?
              </h2>

              <div className="flex flex-col gap-y-3">
                <p>
                  Artists create, and for their creations, they need
                  compensation.
                </p>

                <p>ZionRoyal has developed a series of Web3 based solutions</p>

                <p>for artists and creators all over the world.</p>
                <p>Our aim is simple; we want to help artists create more,</p>
                <p>without needing the help of any one else.</p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <LazyLoadImage
              src={SectionImage}
              alt=""
              effect="blur"
              className="section-image"
            />
          </div>
        </div>
      </div>
      <div
        className="py-24 md:py-40"
        style={{
          backgroundColor: "#000000b3",
          backgroundImage: `url(${SecondSection})`,
          backgroundBlendMode: 'multiply',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="h-full w-full px-12 md:px-20">
          <div className="h-full w-full flex items-center justify-center flex-col md:gap-y-10">
            <div className="flex flex-col items-center justify-center md:items-start w-full">
              <h2 className="text-4xl md:text-6xl font-bold text-heading">
                Launchpad NFTs
              </h2>

              <div className="mt-10 lg:mt-4">  
              <p className="text-center md:text-left">
                The Zion Launchpad is an NFT based utility minting mechanism for
                artist releases.{" "}
              </p>
              <p className="text-center md:text-left">
                Artists can join the LaunchPad, and deploy their own creative
                assets for sale.{" "}
              </p>
              <p className="text-center md:text-left">
                ZionRoyal provide further incentive to supporters, by issuing the
                $MRASTA Token as a return.
              </p>
              </div>

              <a className="p-3 border-1 border-white mt-10" href="/">
                Artist NFT
              </a>
            </div>

            {/* <div className="grid md:grid-cols-3 gap-5">
              <div
                className="p-16 flex flex-col gap-y-4"
                style={{ backgroundColor: "#00000060" }}
              >
                <span className="text-heading text-xl font-bold uppercase">
                  {" "}
                  25% to T4 professor
                </span>
                <ul
                  className="list-disc list-outside"
                  style={{
                    lineHeight: "1.6em",
                  }}
                >
                  <li>Earns 30% APR from music sales</li>
                  <li>5% to Rasta farms</li>
                </ul>
              </div>

              <div
                className="p-16 flex flex-col gap-y-4"
                style={{ backgroundColor: "#00000060" }}
              >
                <span className="text-heading text-xl font-bold uppercase">
                  Staking Tokenomics
                </span>

                <ul
                  className="list-disc list-outside"
                  style={{
                    lineHeight: "1.6em",
                  }}
                >
                  <li>Harvest is locked for 30 days</li>
                  <li>User receives 70% of Harvest (2 years)</li>
                  <li>20% for Marketing</li>
                  <li>
                    10% of all Harvest rewards are put into liquidity for
                    MRASTA/RASTA LP
                  </li>
                </ul>
              </div>

              <div
                className="p-16 flex flex-col gap-y-4"
                style={{ backgroundColor: "#00000060" }}
              >
                <span className="text-heading text-xl font-bold uppercase">
                  Sale Tokenomics
                </span>

                <ul
                  className="list-disc list-outside"
                  style={{
                    lineHeight: "1.6em",
                  }}
                >
                  <li>20% to Music Artist</li>
                  <li>10% to Artist Management / Label</li>
                  <li>15% to Music Artist Marketing Fund</li>
                  <li>25% to Zion Royal </li>
                  <li>25% T4 professor</li>
                  <li>5% to Rasta farms</li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div
        className="py-24 md:py-0 lg:h-screen"
        style={{
          backgroundImage: `url(${SecondSection})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="h-full w-full px-12 md:px-20">
          <div className="h-full w-full flex items-center justify-center flex-col md:gap-y-10">
            <div className="flex flex-col items-center justify-center md:items-start w-full">
              <h2 className="text-4xl md:text-6xl font-bold text-heading">
                Launchpad NFTs
              </h2>

              <div className="mt-10 lg:mt-4">  
              <p className="text-center md:text-left">
                The Zion Launchpad is an NFT based utility minting mechanism for
                artist releases.{" "}
              </p>
              <p className="text-center md:text-left">
                Artists can join the LaunchPad, and deploy their own creative
                assets for sale.{" "}
              </p>
              <p className="text-center md:text-left">
                ZionRoyal provide further incentive to supporters, by issuing the
                $MRASTA Token as a return.
              </p>
              </div>

              <a className="p-5 border-1 border-white mt-10" href="/">
                Artist NFT
              </a>
            </div>

            <div className="grid md:grid-cols-3 gap-5">
              <div
                className="p-16 flex flex-col gap-y-4"
                style={{ backgroundColor: "#00000060" }}
              >
                <span className="text-heading text-xl font-bold uppercase">
                  {" "}
                  25% to T4 professor
                </span>
                <ul
                  className="list-disc list-outside"
                  style={{
                    lineHeight: "1.6em",
                  }}
                >
                  <li>Earns 30% APR from music sales</li>
                  <li>5% to Rasta farms</li>
                </ul>
              </div>

              <div
                className="p-16 flex flex-col gap-y-4"
                style={{ backgroundColor: "#00000060" }}
              >
                <span className="text-heading text-xl font-bold uppercase">
                  Staking Tokenomics
                </span>

                <ul
                  className="list-disc list-outside"
                  style={{
                    lineHeight: "1.6em",
                  }}
                >
                  <li>Harvest is locked for 30 days</li>
                  <li>User receives 70% of Harvest (2 years)</li>
                  <li>20% for Marketing</li>
                  <li>
                    10% of all Harvest rewards are put into liquidity for
                    MRASTA/RASTA LP
                  </li>
                </ul>
              </div>

              <div
                className="p-16 flex flex-col gap-y-4"
                style={{ backgroundColor: "#00000060" }}
              >
                <span className="text-heading text-xl font-bold uppercase">
                  Sale Tokenomics
                </span>

                <ul
                  className="list-disc list-outside"
                  style={{
                    lineHeight: "1.6em",
                  }}
                >
                  <li>20% to Music Artist</li>
                  <li>10% to Artist Management / Label</li>
                  <li>15% to Music Artist Marketing Fund</li>
                  <li>25% to Zion Royal </li>
                  <li>25% T4 professor</li>
                  <li>5% to Rasta farms</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="h-screen py-20"
        style={{
          backgroundImage: `url(${ThirdSection})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: 'fixed'
        }}
      >
        <div className="h-full w-full">
          <div className="h-full w-full flex items-center justify-center flex-col gap-y-4 md:gap-y-8">
            <h2 className="text-4xl md:text-6xl font-bold text-heading">
              Featured NFT Artist
            </h2>
            {/* <span>Buy and stake</span> */}

            <div className="grid grid-cols-1 md:grid-cols-1 gap-3">
            <Link className="border-1 border-white p-3 hover:bg-white hover:text-black" to="/RaggadatCris/0xE98964c1E4Ff584E3fF44EF3599f0a84803Fa50e/stake">Buy and stake</Link>
              {/* <SlickSlide items={pool} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
