import React, { useState, useRef } from "react";
import "react-dropzone-uploader/dist/styles.css";
import "react-notifications/lib/notifications.css";
import Dropzone from "react-dropzone-uploader";
import PulseLoader from "react-spinners/PulseLoader";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import useMint from "../../../hooks/useMint";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { useNftContract } from "../../../hooks/useContract";
import { API_KEY, API_SECRET } from "../../../config/config";
const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
const jsonUrl = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

const allowType = [
  "jpeg",
  "png",
  "gif",
  "svg",
  "mp4",
  "webm",
  "mp3",
  "wav",
  "ogg",
  "glb",
  "gltf",
  "mpeg",
];

export default function Upload() {
  const mainDropzone = useRef();
  const previewDropzone = useRef();
  const { account } = useWallet();
  const nftContract = useNftContract();
  const [requirePreview, setRequirePreview] = useState(false);
  const [type, setType] = useState();
  const [nftName, setNftName] = useState();
  const [artist, setArtist] = useState();
  const [nftPrice, setNftPrice] = useState();
  const [supply, setSupply] = useState();
  const [nftDescription, setNftDescription] = useState();
  const [loading, setLoading] = useState(false);

  const { onMint } = useMint();

  const _handleMain = async (file, status) => {
    if (status === "done") {
      const fileType = file.file.type.split("/");
      if (allowType.indexOf(fileType[1]) === -1) {
        NotificationManager.warning("File not allowed", "", 3000);
        return file.remove();
      }
      setType(fileType[0]);
      if (fileType[0] === "image") {
        setRequirePreview(false);
      } else {
        setRequirePreview(true);
      }
    }

    if (status === "removed") {
      setRequirePreview(false);
    }
  };

  const _handlePrev = async (file, status) => {
    if (status === "done") {
      const fileType = file.file.type.split("/");
      if (allowType.indexOf(fileType[1]) === -1) {
        NotificationManager.warning("File not allowed", "", 3000);
        return file.remove();
      }
      setType(fileType[0]);
      if (fileType[0] !== "image") return file.remove();
    }
  };

  const onCreate = async () => {
    if (loading) return;
    if (!account) return;
    if (!mainDropzone.current.files.length)
      return NotificationManager.warning("Please upload NFT file", "", 3000);
    if (!nftName || !nftPrice || !artist || !supply)
      return NotificationManager.warning("Please fill out all forms", "", 3000);

    const totalSupply = await nftContract.methods.totalSupply().call();

    let nftInfo = {
      url: "",
      preview: "",
      type,
      artist,
      tokenId: Number(totalSupply) + 1,
      name: nftName,
      price: nftPrice,
      maxSupply: supply,
      creater: account,
      description: nftDescription,
      createdAt: new Date().getTime(),
    };

    setLoading(true);
    const mainFile = await uploadFileToPinata(
      mainDropzone.current.files[0].file
    );
    nftInfo.url = `https://zionlabs.mypinata.cloud/ipfs/${mainFile}`;

    if (previewDropzone.current && previewDropzone.current.files.length) {
      const preFile = await uploadFileToPinata(
        previewDropzone.current.files[0].file
      );
      nftInfo.preview = `https://zionlabs.mypinata.cloud/ipfs/${preFile}`;
    }
    await _mint(nftInfo);
    setLoading(false);
  };

  const uploadFileToPinata = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(url, formData, {
        maxContentLength: "Infinity",
        headers: {
          "Content-Type": `multipart/form-data;boundary=${formData._boundary}`,
          pinata_api_key: API_KEY,
          pinata_secret_api_key: API_SECRET,
        },
      });
      return response.data.IpfsHash;
    } catch (error) {
      console.log(error);
    }
  };

  const _mint = async (nftInfo) => {
    try {
      const response = await axios.post(jsonUrl, nftInfo, {
        maxContentLength: "Infinity",
        headers: {
          "Content-Type": `Application/json`,
          pinata_api_key: API_KEY,
          pinata_secret_api_key: API_SECRET,
        },
      });
      const uri = `https://zionlabs.mypinata.cloud/ipfs/${response.data.IpfsHash}`;
      const tx = await onMint(nftPrice, uri, supply, nftInfo.artist);
      if (tx.status === true) {
        NotificationManager.success("Minted successfully", "", 3000);
        mainDropzone.current.files[0].remove();
      } else {
        NotificationManager.warning("Transaction was failed", "", 3000);
      }

      setNftName("");
      setNftPrice(0);
      setSupply(0);
      setNftDescription("");
      setArtist("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex justify-center py-24">
      <div className="upload mint-container px-8 md:px-0 flex w-full md:w-1/2 lg:w-1/3 xl:w-1/5 h-52 flex-col space-y-6">
        <Dropzone
          ref={mainDropzone}
          onChangeStatus={_handleMain}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          inputContent={(files, extra) =>
            extra.reject ? "Drag/Drop Files here" : "Drag/Drop Files here"
          }
          styles={{
            dropzoneActive: { borderColor: "green" },
          }}
        />
        {requirePreview && (
          <Dropzone
            ref={previewDropzone}
            onChangeStatus={_handlePrev}
            maxFiles={1}
            accept="image/jpeg,image/png,image/svg,image/gif"
            multiple={false}
            canCancel={false}
            inputContent={(files, extra) =>
              extra.reject
                ? "Preview Image (Optional)"
                : "Preview Image (Optional)"
            }
            styles={{
              dropzoneActive: { borderColor: "green" },
            }}
          />
        )}
        <div className="w-full space-y-6">
          <input
            type="text"
            placeholder="Artist address"
            className="w-full p-4 border-1 border-gray-400 rounded-md text-black"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
          <input
            type="text"
            placeholder="Item name"
            className="w-full p-4 border-1 border-gray-400 rounded-md text-black"
            value={nftName}
            onChange={(e) => setNftName(e.target.value)}
          />
          <input
            type="number"
            placeholder="Price"
            className="w-full p-4 border-1 border-gray-400 rounded-md text-black "
            value={nftPrice}
            onChange={(e) => setNftPrice(e.target.value)}
          />
          <input
            type="number"
            placeholder="Supply"
            className="w-full p-4 border-1 border-gray-400 rounded-md text-black "
            value={supply}
            onChange={(e) => setSupply(e.target.value)}
          />
        </div>
        <div className="w-full">
          <textarea
            type="text"
            placeholder="Description"
            className="w-full p-4 border-1 border-gray-400 rounded-md text-black h-40"
            style={{
              resize: "none",
            }}
            value={nftDescription}
            onChange={(e) => setNftDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="w-full flex justify-center mt-10">
          <button
            onClick={onCreate}
            disabled={!Boolean(account)}
            className={
              (!Boolean(account) ? "disabled " : "") +
              "w-1/2 flex flex-row text-white py-2 bg-gradient-to-r from-yellow-rasta to-green-rasta items-center justify-center space-x-4 text-xl rounded-xl cursor-pointer"
            }
          >
            {loading ? (
              <PulseLoader size={10} color="white" />
            ) : (
              <span>CREATE</span>
            )}
          </button>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}
