import { useCallback, useEffect, useState } from 'react'
import { abi } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import RastaPrice from './useRastaPrice'

// BUSD-RASTA
const ABI = abi;
const web3 = new Web3("https://bsc-dataseed.binance.org/")
const mrastaRastaPairContract = new web3.eth.Contract(ABI, "0xbea61686d11aed2d078885d77ccaeda352bb1fe4")

const useMRastaPrice = () => {
  const [price, setPrice] = useState(0)
  const rastaPrice = RastaPrice()
  const fetchBalance = useCallback(async () => {
    try {
      const mrastaObj = await mrastaRastaPairContract.methods.getReserves().call();
      const mrastaPrice = new BigNumber(mrastaObj.reserve0).div(mrastaObj.reserve1).times(rastaPrice);
      if (!mrastaPrice.isEqualTo(price)) {
        setPrice(mrastaPrice.toNumber())
      }
    } catch (e) {
      setPrice(0)
    }
  }, [price, rastaPrice]);

  useEffect(() => {
    if (mrastaRastaPairContract) {
      fetchBalance()
    }
  }, [fetchBalance]);

  return price;
}

export default useMRastaPrice
