import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import Upload from "./section/Upload";
import Header from "./section/Header";
import SongTrack from "./section/SongTrack";
import { useState } from "react";
import { useEffect } from "react";
import { useBackend } from "../../hooks/useBackend";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { SERVER_URL } from "../../utils/api";
import { useSelector } from "react-redux";
import { useArtists } from "../../hooks/useAllNFT";
import { adminlist } from "../../config/config";

export default function Profile() {
  const artists = useArtists()
  const { account } = useWallet()
  const { artAddr } = useParams()
  const { onFetchArtist, onFetchMusic } = useBackend()
  const { artist_list, musics } = useSelector(store => store.music)
  const [tabs, setTabs] = useState('songtrack');
  const isAdmin = adminlist.find(e => e === account);

  const artistAddr = useMemo(() => {
    return isAdmin ? artAddr || account : account;
  }, [isAdmin, account, artAddr])

  const isArtist = artists ? artists.find(e => e === artistAddr) : "";

  const artist = useMemo(() => {
    return artist_list ? artist_list.find(item => item.address === artistAddr) : {}
  }, [artist_list, artistAddr])

  const songTrack = useMemo(() => {
    return musics ? musics.filter(item => item.artist === artistAddr) : []
  }, [musics, artistAddr]);

  const [index, setIndex] = useState(0);
  const currentMusic = useMemo(() => {
    return songTrack && ({ ...songTrack[index], index } || {})
  }, [songTrack, index])

  const _onfetch = useCallback(() => {
    onFetchArtist()
    onFetchMusic()
  }, [onFetchArtist, onFetchMusic])

  useEffect(() => {
    _onfetch()
  }, [_onfetch])

  const useAudio = (song) => {
    const [audio] = useState(new Audio(song));
    const [playing, setPlaying] = useState(false);

    if (song) {
      audio.src = `${SERVER_URL}music/${song}`;
    }

    audio.pause();
    audio.load();
    const toggle = () => {
      setPlaying(!playing);
    };

    useEffect(() => {
      if (playing) {
        audio.play().catch((error) => {
          console.log(error);
        });
      } else {
        audio.pause();
        audio.currentTime = 0;
      }
    }, [playing, audio]);

    useEffect(() => {
      audio.addEventListener("ended", () => setPlaying(false));

      return () => {
        audio.removeEventListener("ended", () => setPlaying(false));
      };
    }, [audio]);

    return [playing, toggle, audio];
  };

  const [playing, toggle] = useAudio(currentMusic.file_name);

  const changeMusic = (i) => {
    setIndex(i);
  };
  const handleNext = () => {
    var getCurrentMusic = currentMusic;
    var max = songTrack.length;

    const counter =
      getCurrentMusic.index + 1 === max
        ? getCurrentMusic.index
        : getCurrentMusic.index + 1;
    setIndex(counter);
  };
  const handlePrevious = () => {
    var min = 0;
    var getCurrentMusic = currentMusic;
    const counter =
      getCurrentMusic.index === min
        ? getCurrentMusic.index
        : getCurrentMusic.index - 1;
    setIndex(counter);
  };

  if (isArtist) {
    return (
      <div className="wrapper bg-white artist-container">
        <div className="flex flex-col intro-music">
          <Header
            intro={currentMusic}
            toggle={toggle}
            playing={playing}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            address={artistAddr}
            artist={artist || {}}
            onfetch={_onfetch}
          />
        </div>
        <div
          className="w-full md:w-7/12 justify-center px-8 md:px-0 flex flex-col md:flex-row body mx-auto py-16 pt-0 md:pt-16 space-x-0 space-y-16 md:space-y-0 md:space-x-8"
        >
          <div className="flex md:hidden justify-center flex-flex-row space-x-4">
            <div className="grid grid-cols-2 justify-center md:justify-start tab__title flex-flex-row">
              <button className={(tabs === 'uploader' ? "text-black border-b-2 border-black font-bold text-xs p-2" : "text-gray-500 text-xs p-2")} onClick={() => setTabs('uploader')} >Upload your Song</button>
              <button className={(tabs === 'songtrack' ? "text-black border-b-2 border-black font-bold text-xs p-2" : "text-gray-500 text-xs p-2")} onClick={() => setTabs('songtrack')}>Your Songs</button>
            </div>

            <div className="content">
            </div>
          </div>

          <Upload tabs={tabs} artist={artistAddr} />
          <SongTrack
            track={songTrack}
            useAudio={useAudio}
            toggle={toggle}
            playing={playing}
            currentPlay={currentMusic}
            changeHandler={changeMusic}
            artist={artist || {}}
            tabs={tabs}
            onfetch={_onfetch}
          />
        </div>
      </div>
    );
  } else {
    return <></>
  }

}
