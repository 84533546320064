import React from "react";

export default function BlogSection({ items }) {
  return (
    <div className="row flex flex-col gap-4 md:gap-8 w-full border-1 shadow-outerWhite border-white py-2 md:py-6 px-2 md:px-6 mb-6 text-white justify-between">
      <span className="title italic">Published Article</span>
      {items.map((item, index) => {
        return (
          <div
            className="flex flex-col-reverse md:flex-row  border-1 border-white shadow-outerWhite px-2 py-4 items-center"
            key={index}
          >
            <div className="content flex flex-col space-y-4 w-3/4 px-8">
              <span className="blog-title font-bold">
                <a href="#">{item.title}</a>
              </span>
              <p className="text-gray-200">{item.meta.excerpt}</p>
              <span className="meta-box publish-date italic">
                March, 13 2023
              </span>
            </div>
            <div className="thumbnail w-1/4">
              <img src={item.meta.thumbnailImage.type} className="w-full" />
            </div>
          </div>
        );
      })}
      <div className="footer">
        <a href="#" className="italic">Read More..</a>
      </div>
    </div>
  );
}
