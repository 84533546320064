import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Header from "./section/Header";
import { useParams } from "react-router-dom";
import SongTrack from "./section/SongTrack";
import CardSection from "./section/stack/section/CardSection";
import MobileSection from "./section/stack/mobileSection/CardSection";
import { SERVER_URL } from "../../utils/api";
import 'react-loading-skeleton/dist/skeleton.css'
import { useMobileStatus } from "../../hooks/hook";
import ArtistInfo from "./section/stack/mobileSection/ArtistInfo";
import BlogSection from "./section/stack/BlogSection";
import BlogThumb from "../../assets/profile/blog-thumbnail.jpg";

export default function Profile() {
    const { token_name } = useParams();
    const tokenId = useMemo(() => {
        const _t = token_name.split("_");
        return _t[_t.length - 1];
      }, [token_name]);
    const { pool } = useSelector(store => store.pool)
    const [tabs, setTabs] = useState('farm')
    const _mobile = useMobileStatus()
    const nft = useMemo(() => {
        if (10 ** 20 < tokenId) {
            return pool?.nftlists?.find((item) => item.artist === tokenId) || {};
        }
        return pool?.nftlists?.find((item) => item.id === tokenId) || {};
    }, [pool, tokenId])

    const artist = useMemo(() => {
        return pool?.artistsInfo?.find(item => item.address === nft?.artist) || {}
    }, [pool, nft])

    const songTrack = useMemo(() => {
        return pool?.songInfo?.filter(item => item.artist === nft?.artist) || []
    }, [pool, nft]);

    const [index, setIndex] = useState(0);
    const currentMusic = useMemo(() => {
        return songTrack && ({ ...songTrack[index], index } || {})
    }, [songTrack, index])

    const useAudio = (song = '') => {
        const [audio] = useState(new Audio(song));
        const [playing, setPlaying] = useState(false);

        if (song) {
            audio.src = `${SERVER_URL}music/${song}`;
        }

        audio.pause();
        audio.load();
        const toggle = () => {
            setPlaying(!playing);
        };

        useEffect(() => {
            if (playing) {
                audio.play().catch((error) => {
                    console.log(error);
                });
            } else {
                audio.pause();
                audio.currentTime = 0;
            }
        }, [playing, audio]);

        useEffect(() => {
            audio.addEventListener("ended", () => setPlaying(false));

            return () => {
                audio.removeEventListener("ended", () => setPlaying(false));
            };
        }, [audio]);

        return [playing, toggle, audio];
    };

    const [playing, toggle, audio] = useAudio(currentMusic?.file_name);

    useEffect(() => {
        return () => {
            audio.pause();
        }
    }, [audio])

    const changeMusic = (i) => {
        setIndex(i);
    };
    const handleNext = () => {
        var getCurrentMusic = currentMusic;
        var max = songTrack?.length || 0;

        const counter =
            getCurrentMusic.index + 1 === max
                ? getCurrentMusic.index
                : getCurrentMusic.index + 1;
        setIndex(counter);
    };
    const handlePrevious = () => {
        var min = 0;
        var getCurrentMusic = currentMusic;
        const counter =
            getCurrentMusic.index === min
                ? getCurrentMusic.index
                : getCurrentMusic.index - 1;
        setIndex(counter);
    };
    const blog = [
        {
            'title': 'Raggadat Cris Surprise-Releases New Song ‘5.17’',
            'meta' : {
                'excerpt' : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi viverra, nunc quis varius pulvinar.',
                'publishedDate' : 'March, 13 2023',
                'thumbnailImage': <BlogThumb/>
            }
        },
        {
            'title': 'Raggadat Cris Surprise-Releases New Song ‘5.17’',
            'meta' : {
                'excerpt' : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi viverra, nunc quis varius pulvinar.',
                'publishedDate' : 'March, 13 2023',
                'thumbnailImage': <BlogThumb/>
            }
        }
    ];

    return (
        <div className="bg-black">
            <div className="flex flex-col intro-music">
                <Header
                    nft={nft || {}}
                    intro={currentMusic || {}}
                    toggle={toggle}
                    playing={playing}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    artist={artist || {}}
                />
            </div>
            {
                _mobile ?
                    <div
                        className="w-full md:w-7/12 justify-center px-5 flex flex-col md:flex-row body mx-auto py-16 pt-0 space-x-0 space-y-10"
                    >
                        <div className="flex md:hidden justify-center flex-flex-row">
                            <div className="grid grid-cols-3 justify-center md:justify-start tab__title flex-flex-row mt-8">
                                <button className={(tabs === 'farm' ? "text-white border-b-2 border-white font-bold text-xs p-2" : "text-gray-500 text-xs p-2")} onClick={() => setTabs('farm')} >FARMS</button>
                                <button className={(tabs === 'song' ? "text-white border-b-2 border-white font-bold text-xs p-2" : "text-gray-500 text-xs p-2")} onClick={() => setTabs('song')}>SONGS</button>
                                <button className={(tabs === 'artist' ? "text-white border-b-2 border-white font-bold text-xs p-2" : "text-gray-500 text-xs p-2")} onClick={() => setTabs('artist')}>ARTIST INFO</button>
                            </div>

                            <div className="content">
                            </div>
                        </div>
                        <MobileSection nft={nft || {}} tabs={tabs} />
                        <SongTrack
                            track={songTrack}
                            useAudio={useAudio}
                            toggle={toggle}
                            playing={playing}
                            currentPlay={currentMusic}
                            changeHandler={changeMusic}
                            artist={artist || {}}
                            isMobile={_mobile}
                            tabs={tabs}
                        />
                        <ArtistInfo artist={artist || {}} tabs={tabs} />
                    </div>
                    :
                    <div
                        className="w-full md:w-11/12 lg:w-9/12 justify-center px-8 md:px-0 flex flex-col md:flex-row body mx-auto py-16 pt-0 md:pt-16 space-x-0 space-y-16 md:space-y-0 md:space-x-8"
                    >
                    <SongTrack
                        track={songTrack}
                        useAudio={useAudio}
                        toggle={toggle}
                        playing={playing}
                        currentPlay={currentMusic}
                        changeHandler={changeMusic}
                        artist={artist || {}}
                    />
                        <CardSection nft={nft || {}} className="w-1/4" />
                        <BlogSection items={blog}/>
                    </div>
            }
        </div>
    );
}
