import { useCallback } from 'react'
import { useMrastaContract, useNftContract, useStakeContract } from './useContract'
import { useDispatch } from 'react-redux'
import { pool_store, prenfts_store } from '../redux/actions'
import { allTokens, balanceOf, claimable, getArtists, getStakedBalance, getUserInfo, isApprovedForAll, purchasedTokens, stakedTokens } from '../utils/callHelpers'
import API from '../utils/api'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import Contracts from '../config/constants/constants'
import BigNumber from 'bignumber.js'
import { Raggadat_NFT } from '../config/config'

export const usePool = () => {
    const NftContract = useNftContract()
    const poolContract = useStakeContract()
    const dispatch = useDispatch()
    const { account } = useWallet()

    const _handleFetchNft = useCallback(async () => {
        try {
            const tokenuris = await allTokens(NftContract);
            dispatch(prenfts_store(tokenuris));

            let userStakedTokenIds = [];
            let userPurchasedTokens = [];
            if (account) {
                userPurchasedTokens = await purchasedTokens(NftContract, account);
                userStakedTokenIds = await stakedTokens(poolContract, account);
            }

            let tokeninfos = [];
            for (let i = 0; i < tokenuris.length; i++) {
                try {
                    const uri = tokenuris[i].tokenId < 4 ? `${Raggadat_NFT[i]}` : tokenuris[i].uri;
                    const res = await fetch(uri);
                    const json = await res.json();
                    tokeninfos.push({
                        ...json,
                        id: tokenuris[i].tokenId,
                        url: json.url,
                        owner:
                            userStakedTokenIds.find((e) => e.tokenId === tokenuris[i].tokenId) || userPurchasedTokens.find((e) => e.tokenId === tokenuris[i].tokenId)
                                ? account : tokenuris[i].artist,
                        supply: tokenuris[i].balance
                    });
                } catch (error) {
                    console.log(error)
                }
            }
            return tokeninfos;
        } catch (error) {
            console.log(error)
            return []
        }

    }, [NftContract, poolContract, account, dispatch]);

    const _handleFetchArtists = useCallback(async () => {
        try {
            const artists = await getArtists(NftContract);
            return artists;
        } catch (error) {
            console.log(error)
            return []
        }
    }, [NftContract])

    const _handleFetchArtistInfo = useCallback(async () => {
        try {
            const { data } = await API('GET', 'get-artist-list', {});
            if (!data) {
                console.log('Server error!')
                return [];
            }
            return data;
        } catch (error) {
            console.error(error);
            return []
        }
    }, [])

    const _handleFetchSongs = useCallback(async () => {
        try {
            const { data } = await API('GET', 'get-music-list', {});
            return data;
        } catch (error) {
            console.error(error);
            return []
        }
    }, [])

    const onfetch = useCallback(async () => {
        const nftlists = await _handleFetchNft();
        dispatch(pool_store({ nftlists }))
        const artistlists = await _handleFetchArtists();
        dispatch(pool_store({ artistlists }))
        const artistsInfo = await _handleFetchArtistInfo();
        dispatch(pool_store({ artistsInfo }))
        const songInfo = await _handleFetchSongs();
        dispatch(pool_store({ songInfo }))
        // const pool = {
        //     nftlists,
        //     artistlists,
        //     artistsInfo,
        //     songInfo
        // };
    }, [_handleFetchNft, _handleFetchArtists, _handleFetchArtistInfo, _handleFetchSongs, dispatch])

    return { onfetch };
}

export const useFarmInfo = () => {
    const poolContract = useStakeContract();

    const _handleFetch = useCallback(async () => {
        const totalSupply = await poolContract.methods.totalSupply().call();
        const rewardRate = await poolContract.methods.rewardRate().call();
        return {
            totalSupply,
            rewardRate
        }
    }, [poolContract.methods])

    return { onFetchFarmInfo: _handleFetch };
}

export const useUserInfo = () => {
    const poolContract = useStakeContract();
    const NftContract = useNftContract();
    const mrastaContract = useMrastaContract();
    const { account } = useWallet();

    const _handleFetch = useCallback(async () => {
        if (account) {
            const pendingRewards = await claimable(poolContract, account);
            const userStatedTokensBalance = await getStakedBalance(poolContract, account);
            const userStatedTokenIds = await stakedTokens(poolContract, account);
            const approved = await isApprovedForAll(NftContract, Contracts.stake.address, account);
            const mrastaBalance = await balanceOf(mrastaContract, account);
            const userInfo = await getUserInfo(poolContract, account);
            const userPurchasedTokens = await purchasedTokens(NftContract, account);

            return {
                pendingRewards,
                userStatedTokensBalance,
                userStatedTokenIds,
                approved,
                mrastaBalance: new BigNumber(mrastaBalance).div(new BigNumber(10).pow(18)).toJSON(),
                depositedAt: userInfo.depositedAt,
                stakedAmount: userInfo.amount,
                purchasedNfts: userPurchasedTokens
            }
        } else {
            return false
        }
    }, [poolContract, NftContract, mrastaContract, account])

    return { onfetchUserInfo: _handleFetch };
}

export default usePool