import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as FaIcons from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Pagination, Scrollbar, A11y, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import { useMobileStatus } from "../../../hooks/hook";
import { SERVER_URL } from "../../../utils/api";
import { Raggadat_NFT } from "../../../config/config";

export default function MoreArtist({ items, artist }) {
  const isMobile = useMobileStatus()
  const moreNfts = useMemo(() => {
    return items?.filter(item => item.artist === artist.address) || [];
  }, [items, artist])

  return (
    <div className="current-launchpad flex flex-col space-y-8">
      <div className="header flex flex-col md:flex-row items-center">
        <div className="title-bar flex-grow-1">
          <h2 className="text-xl md:text-2xl font-bold">More from this Artist</h2>
        </div>
        <Link
          className="browse flex-row hidden md:flex items-center space-x-4"
          to={artist.name ? `/list/artist/${String(artist.name).replaceAll(" ", "")}` : "#"}
        >
          <span>Browse More</span>
          <span>
            <FaIcons.FaArrowRight />
          </span>
        </Link>
      </div>
      <div className="body">
        {
          isMobile ? (
            <Swiper
              grabCursor={true}
              centeredSlides={false}
              slidesPerView={2.2}
              effect={"coverflow"}
              modules={[EffectCoverflow]}
              coverflowEffect={{
                rotate: 0,
                stretch: -20,
                depth: 100,
                modifier: 1,
                slideShadows: false
              }}
              className="w-11/12"
            >
              {
                moreNfts.map((item, index) => (
                  <SwiperSlide key={index} style={{ padding: 5 }}>
                    <MobileView artist={artist} item={item} i={index} />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          ) : (
            <Swiper
              modules={[Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={4}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {moreNfts.map((item, index) => {
                return (
                  <SwiperSlide key={index} style={{ padding: 5 }}>
                    <DesktopView artist={artist} item={item} i={index} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )
        }
      </div>
    </div>
  );
}

const MobileView = ({ item, artist, i }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const uri = Number(item.tokenId) < 4 ? `${Raggadat_NFT[i]}` : item.uri;
        const res = await fetch(uri);
        const json = await res.json();
        setData({
          ...json,
          id: item.tokenId,
          url: json.url,
        });
      } catch (error) {
        console.log(error)
      }
    })()
  }, [item, i])

  return (
    <Link
      to={artist && data.id ? `/${String(artist?.name)?.replaceAll(" ", "")}/${data?.name}_${data?.id}` : "#"}
      className="card current__launchpad__item flex flex-col hover:shadow-box"
    >
      <div className="thumbnail more-artists-img" style={{ height: 160 }}>
        {
          data.description ?
            <LazyLoadImage src={`${SERVER_URL}videos/raggadat-cris-${data.id}.gif`} alt={data.name} style={{ height: "100%" }} className="w-full h-full object-cover" />
            :
            <ClipLoader color="#0006" />
        }
      </div>
      <div className="song-detail my-4 px-4 flex flex-col gap-2">
        {
          data.description ?
            <div className="artist-name text-md font-bold">
              {data.name}
            </div>
            :
            <Skeleton height={30} baseColor="#E7E9EB" highlightColor="white" />
        }
        {
          data.description ?
            // <div className="artist-name text-sm" style={{ color: "red" }}>
            //   {data.price} MRASTA
            // </div>
            <div className="artist-name text-sm text-blue-zion">
              {data.price} MRASTA
            </div>
            :
            <Skeleton height={30} baseColor="#E7E9EB" highlightColor="white" />
        }
      </div>
    </Link>
  )
}

const DesktopView = ({ item, artist, i }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const uri = Number(item.tokenId) < 4 ? `${Raggadat_NFT[i]}` : item.uri;
        const res = await fetch(uri);
        const json = await res.json();
        setData({
          ...json,
          id: item.tokenId,
          url: json.url,
        });
      } catch (error) {
        console.log(error)
      }
    })()
  }, [item, i])

  return (
    <Link
      to={artist && data.id ? `/${String(artist?.name)?.replaceAll(" ", "")}/${data?.name}_${data?.id}` : "#"}
      className="card current__launchpad__item flex flex-col gap-2 hover:shadow-box"
    >
      <div className="thumbnail more-artists-img">
        {
          data.description ?
            (data.type === 'video' && !data.preview ?
              <video
                className="w-full h-full object-cover"
              >
                <source src={data.url} type='video/mp4' />
              </video>
              :
              <LazyLoadImage src={data.preview || data.url} alt={data.name} className="w-full h-full object-cover" />)
            :
            <ClipLoader color="#0006" />
        }
      </div>
      <div className="song-detail my-4 px-4 flex flex-col space-y-2">
        {
          data.description ?
            <div className="artist-name text-xl font-bold text-center md:text-left">
              {data.name}
            </div>
            :
            <Skeleton height={30} baseColor="#E7E9EB" highlightColor="white" />
        }
        {
          data.description ?
            // <div className="artist-name text-md" style={{ color: "red" }}>
            <div className="artist-name text-sm text-blue-zion">
              {data.price} MRASTA
            </div>
            :
            <Skeleton height={30} baseColor="#E7E9EB" highlightColor="white" />
        }
      </div>
    </Link>
  )
}

